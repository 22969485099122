@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

*, body {
    font-family: 'Noto Sans', sans-serif !important;
    margin: 0;
    padding: 0;
}

.Heading {
    font-family: 'Roboto Slab', serif !important;
}

.Header {
    width: 100%;
    background: #e64f22;
    padding: 0;
    position: fixed;
    z-index: 9999;
}

.Container {
    width: 1140px;
    margin: 0 auto;
}

.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.MainDiv {
    display: flex;
    width: 100%;
}

.LoginFormSec {
    width: 60%;
    height: 100%;
    position: fixed;
    background: #f3f3f3;
    top: 0;
    right: 0;
}

.LohinForm {
    width: 100%;
    max-width: 500px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 100%;
    overflow: auto;
}

.LoginLogo {
    width: 210px !important;
    height: 80px !important;
    object-fit: contain !important;
    object-position: center !important;
    margin-bottom: 50px;
    position: absolute !important;
    top: 20px;
    left: 30px;
    z-index: 1;
}

.FormInput {
    width: 100%;
    height: 48px;
    background-color: #fff;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 25px;
}

.FormInput:focus {
    outline: none;
}

.LoginBU {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    background-color: #e64f22;
    border-radius: 8px;
    height: 48px;
    margin-top: 15px;
    text-transform: uppercase;
    border: none;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.LoginBU span {
    position: relative;
    z-index: 1;
}

.LoginBU:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background-color: rgba(0,0,0,.14);
    z-index: 0;
}

.LoginBU:hover:after {
    width: 100%;
    left: 0;
}

.LoginFormSText {
    font-size: 13px;
    font-weight: 500;
    color: #98a2b1;
    line-height: normal;
    padding: 10px 10px 0 10px;
    text-align: center;
}

.LoginFormSText a {
    color: #e64f22 !important;
    text-decoration: none;
    position: relative;
    transition: all .3s ease-in-out;
}

.LoginFormSText a:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    background-color: #e64f22;
    left: 0;
    bottom: -3px;
    transition: all .3s ease-in-out;
}

.LoginFormSText a:hover:after {
    width: 100%;
}

.LoginBGSec {
    width: 40%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
}

.LoginBGSec:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(230, 79, 34, .6);
    left: 0;
    top: 0;

}

.LoginBGSec img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 0;
}

.BookingFormTitle {
    font-size: 28px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
}

.LoginformSmTitle {
    font-size: 18px;
    font-weight: 500;
    color: #918f8f;
    margin-bottom: 30px;
    width: 100%;
    text-align: left;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
    font-size: .875rem;
    justify-content: space-between;
}

.FormRow .CheckField {
    display: flex;
    flex-wrap: nowrap;
    font-size: .875rem;
    justify-content: space-between;
}

.FormRow .CheckField .LoginFormSText{
    font-size: 13px;
    font-weight: 500;
    color: #98a2b1;
    line-height: normal;
    padding: 3px 0px 3px 10px;
    text-align: center;
    margin: 0;
}

.FormGroup {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 48%;
}

.FormGroupFull {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 100%;
}

.FormGroupOneThird {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 31%;
}

.FormLabel {
    color: #333;
    font-size: 13px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
}

.login_FormLabel {
    position: relative;
}

.login_FormLabel svg{
    position: absolute;
    width: 20px;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
    color: #ffa084;
}

.FormControl {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #495057;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    height: 42px;
    line-height: 1.5;
    min-width: 150px;
    padding: 0.375rem 0.4rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}

.FormControl:focus {
    outline: none;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 4px;
}

.BackToHome,
.BackToHome:hover {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: #006;
    display: flex;
}

.BackToHome:after {
    content: '';
    position: absolute;
    width: 0%;
    height: 1px;
    background-color: #000066;
    left: 29px;
    bottom: -3px;
    transition: all .3s ease-in-out;
}

.BackToHome:hover:after {
    width: Calc(100% - 29px);
}

.BackToHome svg {
    min-width: 18px;
    height: 18px;
    margin-right: 10px;
    color: #e64f22;
}

.LoginLogoSmRe {
    width: 210px !important;
    height: 80px !important;
    object-fit: contain !important;
    object-position: center !important;
    margin-bottom: 50px;
}

@media (min-width: 360px) {
    .LoginBGSec {
        display: none;
    }
    .LoginFormSec {
        width: 100%;
    }
    .BookingFormTitle {
        text-align: center;
    }    
    .LoginformSmTitle {
        text-align: center;
    }
    .BackToHome {
        width: 20px;
        white-space: nowrap;
        overflow: hidden;
        top: 5px;
        left: 5px;
    }
    .LohinForm {
        max-width: 320px;
        padding: 50px 0;
    }
    .FormGroup {
        width: 100%;
    }
}

@media (min-width: 576px) {
    .LohinForm {
        max-width: 500px;
        padding: 0;
    }
    .FormGroup {
        width: 48%;
    }
}

@media (min-width: 992px) {
    .LoginBGSec {
        display: block;
    }
    .LoginFormSec {
        width: 60%;
    }
    .BookingFormTitle {
        text-align: left;
    }    
    .LoginformSmTitle {
        text-align: left;
    }
    .LoginLogoSmRe {
        display: none;
    }
    .BackToHome {
        width: auto;
        white-space: unset;
        overflow: unset;
        top: 20px;
        left: 20px;
    }
}