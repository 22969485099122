@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

*, body {
    font-family: 'Noto Sans', sans-serif !important;
    margin: 0;
    padding: 0;
}

.Heading {
    font-family: 'Roboto Slab', serif !important;
}

.Header {
    width: 100%;
    background: #e64f22;
    padding: 0;
    position: sticky;
    z-index: 999;
    top: 0;
}

.Container {
    width: 1140px;
    margin: 0 auto;
    position: relative;
}

.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.BookingSec {
    width: 100%;
    position: relative;
    padding: 50px 0;
}

.BackgroundBG {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .6;
}

.BookingUnderDiv {
    width: 100%;
    background-color: #fff;
    box-shadow: 2px 5px 15px 1px rgba(0, 0, 0, 0.11);
    border-radius: 12px;
    padding: 40px 30px;
}

.BookingUnderDiv1 {
    background-color: #F9DAD0;
}

.BookingUnderDiv2 {
    background-color: #D1E7FC;
}

.StepperSec {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 40px auto 55px auto;
    align-items: flex-start !important;
}
.StepperCard {
    width: Calc(100% / 6);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
}
.StepperCard:after {
    content: '';
    position: absolute;
    width: 50%;
    height: 1px;
    background: #DEDFE3;
    left: 0;
    top: 14px;
}
.StepperCard::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 1px;
    background: #DEDFE3;
    right: 0;
    top: 14px;
}
.StepperSec .StepperCard:first-child::after {
    display: none
}
.StepperSec .StepperCard:last-of-type::before {
    display: none
}
.StepperCard.Completed::after,
.StepperCard.Completed::before {
    background: #27C27B;
}
.StepperCard.Active::after {
    background: #27C27B;
}
.StepperCard.Failed::after {
    background: #27C27B;
}
.StepIcon {
    width: 28px;
    height: 28px;
    background-color: #fff;
    border: 1px solid #E1E1E1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: #fff solid 18px;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
}
.StepperCard.Completed .StepIcon {
    background-color: #27C27B;
    border: none;
}
.StepperCard.Active .StepIcon {
    width: 20px;
    height: 20px;
    background-color: #e64f22;
    margin-top: 4px;
    margin-bottom: 24px;
    border: none;
}
.StepperCard.Failed .StepIcon {
    width: 20px;
    height: 20px;
    background-color: #F44336;
    margin-top: 4px;
    margin-bottom: 24px;
    border: none;
}
.StepperCard.Active .StepIcon:after {
    content: '';
    width: 28px;
    height: 28px;
    background: transparent;
    border: 1.5px solid #e64f22;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.StepperCard.Failed .StepIcon:after {
    content: '';
    width: 28px;
    height: 28px;
    background: transparent;
    border: 1px solid #F44336;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.StepperCard.Failed .StepIcon:before {
    content: '!';
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.StepIcon svg {
    width: 20px;
    display: none;
    color: #fff;
}
.StepperCard.Completed .StepIcon svg {
    display: block;
}
.StepTitle {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #343434;
    margin: 0;
}
.StepTitle span {
    width: 120px;
    text-align: center;
    display: inline-block;
}
/* .StepperCard.Completed .StepTitle,
.StepperCard.Active .StepTitle {
  color: #343434;
} */
.StepperCard.Failed .StepTitle {
    color: #F44336;
}

.StepBoxTitle {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #363636;
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 15px;
}

.StepBoxTitle:after {
    content: '';
    width: 40px;
    height: 5px;
    background: #E64F22;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
}

.StepBoxTitle2 {
    width: 100%;
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #363636;
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 15px;
}

.StepBoxTitle2:after {
    content: '';
    width: 40px;
    height: 5px;
    background: #E64F22;
    position: absolute;
    left: 0%;
    bottom: 0;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.FormRowHS {
    width: 50%;
    padding: 15px;
}

.FormRowHSFull {
    width: 100%;
}

.FormRow1 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.FormRow1Half {
    width: 50%;
    padding: 0 15px;
}

.FormRow160 {
    width: 60%;
    padding: 0 15px;
}

.FormRow140 {
    width: 40%;
    padding: 0 15px;
}

.FormRow1Full {
    width: 100%;
    padding: 0 15px;
}

.FormField {
    width: 100%;
    padding: 8px 0;
}

.FormField2 {
    width: 100%;
    padding: 8px 0;
    max-width: 500px;
}

.FormField label,
.FormField2 label {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 7px;
    color: #4D4D4D;
    width: 100%;
}

.FormField label span {
    color: #f40;
}

.FormField.CusWidth {
    width: 180px;
    margin-right: 15px;
}

.CustomSelect>div {
    border-color: #E1E1E1 !important;
    border-radius: 8px !important;
    min-height: 44px !important;
    font-weight: 400;
    font-size: 14px;
}

.TextField {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E1E1E1 !important;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 11px 16px !important;
}

.TextField div,
.TextField div input {
    padding: 0 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333;
}

.TextFieldAutoComplete{
    padding: 0;
    border:none;
}

.TextFieldAutoComplete input{
    padding: 2px 7px !important;
}

textarea.TextField {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 11px 16px;
    height: 160px;
    resize: none;
}

.TextField:focus,
textarea.TextField:focus {
    outline: none;
}

.BookADelivery {
    width: 100%;
    padding: 10px 0 0px 0;
}

.CheckBox span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #000000;
}

.BookDeliveryCard {
    width: 100%;
    position: relative;
    background: #f5f5f5;
    border-radius: 8px;
    padding-right: 52px;
    /* overflow: hidden; */
    margin-bottom: 15px;
}

.BookDeliveryCardUnder {
    width: 100%;
    padding: 15px 20px;
}

.BookDeliveryCardUnderRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.BookDeliveryFormCard25 {
    width: 25%;
    padding: 0 15px;
}

.BookDeliveryFormCard30 {
    width: 30%;
    padding: 0 15px;
}

.BookDeliveryFormCard20 {
    width: 20%;
    padding: 0 15px;
}

.BookDeliveryFormCard10 {
    width: 10%;
    padding: 0 15px;
}

.BookDeliveryFormCard100 {
    width: 100%;
    padding: 0 15px;
}

.CardTrashBU {
    position: absolute;
    top: 0;
    right: 0;
    width: 53px;
    height: 100%;
    background: #e64f22;
    border: none;
    color: #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.HomeBU {
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 28px;
    text-transform: uppercase;
    height: 48px;
    border-radius: 8px;
    border: none;
    background: #e64f22;
    margin: 28px auto 40px auto;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.NextBU {
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 28px;
    text-transform: uppercase;
    height: 48px;
    border-radius: 8px;
    border: none;
    background: #e64f22;
    margin: 28px 0 0px 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    min-width: 130px;
}

.NextBU:disabled,
.NextBU[disabled] {
    opacity: .5;
    cursor: auto;
}

.HomeBU:before,
.NextBU:before {
    content: "";
    position: absolute;
    top: -180%;
    left: -100%;
    width: 150%;
    height: 300%;
    opacity: 0;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    background: hsla(0,0%,100%,0);
    background: -webkit-gradient(linear,left top,right top,from(hsla(0,0%,100%,0)),color-stop(70%,hsla(0,0%,100%,0)),color-stop(74%,hsla(0,0%,100%,.4)),color-stop(76%,hsla(0,0%,100%,.2)),color-stop(77%,hsla(0,0%,100%,0)),color-stop(79%,hsla(0,0%,100%,.1)),color-stop(92%,hsla(0,0%,100%,.5)),to(hsla(0,0%,100%,0)));
    background: -webkit-linear-gradient(left,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
    background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
}

.NextBU:disabled::before,
.NextBU[disabled]::before {
    display: none;
}

.HomeBU:hover:before,
.NextBU:hover:before{
    opacity: 1;
    top: -160%;
    left: 15%;
    -webkit-transition-property: left,top,opacity;
    transition-property: left,top,opacity;
    -webkit-transition-duration: .7s,.7s,.15s;
    transition-duration: .7s,.7s,.15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

.BookADeliveryCardArea {
    width: 100%;
    border-bottom: 1px solid #DEDFE0;
    margin-bottom: 30px;
}

.UploadImage {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -10px;
}

.UploadImageArea {
    width: 170px;
    height: 100px;
    padding: 0 10px;
}

.UploadImageAreaU {
    width: 100%;
    height: 100%;
    background: #fff;
    border: 1px dashed #E64F22;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.UploadImageAreaU svg {
    width: 24px;
    height: 24px;
    color: #E64F22;
    margin-bottom: 3px;
}

.UploadImageAreaU p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin: 0;
    width: 100%;
    text-align: center;
    color: #000000;
}

.UploadImageUS {
    width: 100%;
    height: 100%;
    position: relative;
}

.UploadImageUS img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
}

.UploadImageUS button {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e64f22;
    color: #fff;
    border: none;
    border-radius: 50%;
}

.UploadImageUS button svg {
    width: 18px;
    height: 18px;
}

.BookingNotesArea {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.BookingNotesSec65 {
    width: 65%;
}

.BookingNotesSec35 {
    width: 35%;
    border-left: 1px solid #DEDFE0;
    padding-left: 40px;
}

.CostUL {
    margin: 0;
    padding: 0;
    width: 100%;
}

.CostUL li {
    list-style: none;
    display: flex;
    padding: 6px 0;
}

.CostTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #000000;
    width: 180px;
}

.CostNo {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #000000;
}

.CostTitle.Big,
.CostNo.Big {
    font-size: 22px;
    line-height: 30px;
}

.StepperBUSec {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #D3D3D3;
    margin-top: 30px;
}

.FormInsideRow {
    display: flex;
}

.Opacity0 {
    opacity: 0;
}

.FormLink,
.FormLink:hover {
    font-size: 16px;
    font-weight: 600;
    color: #e64f22;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 2px 0 15px 0;
    background: transparent;
    border: none;
}

.FormLink svg {
    width: 22px;
    margin-right: 8px;
}

.FindLocationBU {
    background: #E64F22;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    width: 200px;
    margin-left: 15px;
    border: none;
}

.SummerySec {
    width: 100%;
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    padding: 40px 0;
    margin-bottom: 40px;
    background: linear-gradient(to right,  #F9DAD0 0%,#F9DAD0 50%,#D1E7FC 50%,#D1E7FC 100%);
}

.SummerySecUnder {
    width: 80%;
    margin: auto;
}

.SummerySecRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.SummerySecHalf {
    width: 45%;
}

.SummeryListSec {
    width: 100%;
    margin: 0;
    padding: 0;
}

.SummeryListSec li {
    list-style: none;
    display: flex;
    padding: 10px 0;
}

.SummeryHeadTitle {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #868686;
    width: 120px;
}

.SummeryHeadText {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #4D4D4D;
    width: 200px;
}

.TableSec {margin-bottom: 80px;}

.TableSec,
.TableSec table {
    width: 100%;
}

.TableHead {
    padding: 12px 14px;
    background: #E64F22;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    border: 1px solid #EFDCD6;
}

.TableCon {
    padding: 12px 14px;
    background: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #626262;
    border: 1px solid #EFDCD6;
}

.SummeryPPTI {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    padding: 8px 0;
    color: #000000;
    text-align: right;
}

.SummeryPPTE {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    padding: 8px 0;
    color: #000000;
}

.SummeryPPTI.Big,
.SummeryPPTE.Big {
    font-size: 22px;
}

.TableConSec {
    width: 100%;
    display: flex;
    padding: 8px 0;
}

.TableConSecTitle {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #868686;
    min-width: 130px;
}

.TableConSecText {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #4D4D4D;
}

.Checkout {
    width: 40%;
    margin: 100px auto;
}

.GuestBU {
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 28px;
    text-transform: uppercase;
    height: 56px;
    border-radius: 8px;
    border: none;
    background: #e64f22;
    margin: 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.SignUp {
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 28px;
    text-transform: uppercase;
    height: 56px;
    border-radius: 8px;
    border: none;
    background: #002e5b;
    margin: 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.SignUpNew{
    float: right;
    font-size: 16px;
    font-weight: 600;
    color: #e64f22;
    text-decoration: underline;
    display: flex;
    align-items: center;
    padding: 2px 0 15px 0;
    background: transparent;
    border: none;
}

.GuestBU::before,
.SignUp::before {
    content: "";
    position: absolute;
    top: -300%;
    left: -100%;
    width: 150%;
    height: 300%;
    opacity: 0;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    background: hsla(0,0%,100%,0);
    background: -webkit-gradient(linear,left top,right top,from(hsla(0,0%,100%,0)),color-stop(70%,hsla(0,0%,100%,0)),color-stop(74%,hsla(0,0%,100%,.4)),color-stop(76%,hsla(0,0%,100%,.2)),color-stop(77%,hsla(0,0%,100%,0)),color-stop(79%,hsla(0,0%,100%,.1)),color-stop(92%,hsla(0,0%,100%,.5)),to(hsla(0,0%,100%,0)));
    background: -webkit-linear-gradient(left,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
    background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
}

.GuestBU:hover:before,
.SignUp:hover:before {
    opacity: 1;
    top: -300%;
    left: 15%;
    -webkit-transition-property: left,top,opacity;
    transition-property: left,top,opacity;
    -webkit-transition-duration: .7s,.7s,.15s;
    transition-duration: .7s,.7s,.15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

.CheckoutText {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    margin: 30px 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
}

.CheckoutText span {
    background: #fff;
    padding: 0 5px;
    position: relative;
    z-index: 2;
}

.CheckoutText:after {
    background: #999;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 80%;
    z-index: 1;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    width: 100%;
    margin-top: 2px;
    margin-bottom: 0;
}

.AltConSec {
    width: 100%;
    position: relative;
}

.AltConSec button {
    position: absolute;
    top: 0;
    right: -55px;
    width: 45px;
    height: 45px;
    border: none;
    background: #e64f22;
    color: #fff;
    border-radius: 6px;
}

.AllAddSec {
    width: 100%;
    display: flex;
}

.AllAddSec button {
    margin-left: 10px;
    width: 45px;
    height: 45px;
    border: none;
    background: #e64f22;
    color: #fff;
    border-radius: 6px;
}

.BookingDone {
    width: 100%;
    display: flex;
    height: 500px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.BookingDone img {
    width: 150px;
    height: 150px;
    margin-bottom: 30px;
}

.BookingTitle {
    font-size: 48px;
    font-weight: 700;
    color: #008000;
    margin-bottom: 8px;
    width: 100%;
    text-align: center;
}

.BookingSmTitle {
    font-size: 16px;
    font-weight: 600;
    color: #767676;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
}

.LohinForm {
    width: 100%;
    max-width: 500px;
}

.ModalBody {
    width: 100%;
    padding: 40px 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
}

.PopupHeading {
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    color: #262626;
    margin-bottom: 30px;
}

.Tabs {
    width: 100%;
    display: flex;
}

.TabCard {
    display: flex;
    align-items: center;
    color: #545454;
    padding: 5px 10px;
    margin-bottom: -1px;
    border: 1px solid #E64F22;
    border-bottom: 0;
    background-color: #E64F22;
    color: #fff;
}

.TabCard.Active {
    border: 1px solid #E64F22;
    border-bottom: 0;
    background-color: #fff;
    color: #E64F22;
}

.TabCard svg {
    width: 16px;
    margin-right: 6px;
    height: 16px;
}

.TabCard p {
    margin: 0;
    font-size: 14px;
}

.TabErrorM {
    width: 100%;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: #f40;
    margin: 0 0 0 0;
}

.FormSec {
    width: 500px;
    background: #FFFFFF;
    border: 1px solid #E64F22;
    border-radius: 0px;
    padding: 3px 3px 3px 10px;
    display: flex;
}

.FormSec input {
    width: 100%;
    border: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #858585;
}

.FormSec input:focus {
    outline: none;
}

.FormSec button {
    font-size: 15px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 6px 1px;
    text-transform: uppercase;
    height: 40px;
    border-radius: 0px;
    border: none;
    background: #E64F22;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    /* margin: 80px auto 0 auto; */
    width: 100px;
}

.FormSec button::before {
    content: "";
    position: absolute;
    top: -180%;
    left: -100%;
    width: 150%;
    height: 300%;
    opacity: 0;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    background: hsla(0,0%,100%,0);
    background: -webkit-gradient(linear,left top,right top,from(hsla(0,0%,100%,0)),color-stop(70%,hsla(0,0%,100%,0)),color-stop(74%,hsla(0,0%,100%,.4)),color-stop(76%,hsla(0,0%,100%,.2)),color-stop(77%,hsla(0,0%,100%,0)),color-stop(79%,hsla(0,0%,100%,.1)),color-stop(92%,hsla(0,0%,100%,.5)),to(hsla(0,0%,100%,0)));
    background: -webkit-linear-gradient(left,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
    background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
}

.FormSec button:hover:before {
    opacity: 1;
    top: -160%;
    left: 15%;
    -webkit-transition-property: left,top,opacity;
    transition-property: left,top,opacity;
    -webkit-transition-duration: .7s,.7s,.15s;
    transition-duration: .7s,.7s,.15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

.ModalCloseBU,
.ModalCloseBU:hover {
    position: absolute;
    top: -17px;
    right: -21px;
    background: #ffffff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    cursor: pointer;
    border: none;
}

.ModalCloseBU svg {
    width: 22px;
    opacity: .8;
}

@media (min-width: 360px) {
    .LohinForm {
        max-width: 320px;
    }
}

@media (min-width: 576px) {
    .LohinForm {
        max-width: 400px;
    }
}

