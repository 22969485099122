.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 4px;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
    font-size: .875rem;
    justify-content: space-between;
}

.FormRow .CheckField {
    display: flex;
    flex-wrap: nowrap;
    font-size: .875rem;
    justify-content: space-between;
}

.FormRow .CheckField .LoginFormSText{
    font-size: 13px;
    font-weight: 500;
    color: #98a2b1;
    line-height: normal;
    padding: 3px 0px 3px 10px;
    text-align: center;
    margin: 0;
}

.FormGroup {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 48%;
}

.FormGroupFull {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 100%;
}

.FormGroupOneThird {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 31%;
}

.FormLabel {
    color: #333;
    font-size: 13px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
    width: 100%;
}

.login_FormLabel {
    position: relative;
    width: 100%;
}

.login_FormLabel svg{
    position: absolute;
    width: 20px;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
    color: #ffa084;
}
.FormControl {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #495057;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    height: 42px;
    line-height: 1.5;
    min-width: 150px;
    padding: 10px 10px 11px 45px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}

.FormControl:focus {
    outline: none;
}

.LoginBU {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    background-color: #e64f22;
    border-radius: 8px;
    height: 48px;
    margin-top: 15px;
    text-transform: uppercase;
    border: none;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.LoginBU span {
    position: relative;
    z-index: 1;
}

.LoginBU:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background-color: rgba(0,0,0,.14);
    z-index: 0;
}

.LoginBU:hover:after {
    width: 100%;
    left: 0;
}


@media (min-width: 360px) {
    .FormGroup {
        width: 100%;
    }
}
