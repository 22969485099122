@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

*, body {
    font-family: 'Noto Sans', sans-serif !important;
    margin: 0;
    padding: 0;
}

.Heading {
    font-family: 'Roboto Slab', serif !important;
}

.Container {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
}

.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0px;
}

.Footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #e64f22;
    position: relative;
}

/* .Footer:after {
    content: '';
    background-image: url("../img/world-map.png");
    position: absolute;
    top: 0;
    left: 0;
    width: 650px;
    height: 340px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: .2;
} */

.LogoSec {
    width: 30%;
    padding: 15px;
}

.LogoSec img {
    width: 250px;
    margin-bottom: 20px;
}

.FootCon {
    font-size: 14px;
    color: #fff;
}

.FootSec {
    width: 23.33%;
    padding: 15px;
}

.FootSecTitle {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    padding-top: 30px;
    margin-bottom: 8px;
}

.FootSec ul {
    padding: 0;
    margin: 0;
}

.FootSec ul li {
    list-style: none;
    padding: 5px 0;
}

.FootSec ul li a {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    transition: all .3s ease-in-out;
}

.FootSec ul li a:hover {
    color: #002e5b;
}

.FootIcon {
    display: flex;
    align-items: center;
}

.FootIcon a {
    font-size: 22px;
    color: #fff;
    padding-right: 15px;
    transition: all .3s ease-in-out;
}

.FootIcon a svg {
    width: 22px;
    fill: #fff;
}

.FootIcon a:hover svg {
    fill: #002e5b;
}

.FootPadd {
    padding: 50px 0 30px 0;
    border-bottom: 1px solid #959595;
}

.CopyText {
    width: 100%;
    font-size: 12px;
    text-align: center;
    color: #fff;
    margin: 10px 0;
}

@media (min-width: 360px) {
    .LogoSec {
        width: 100%;
    }
    .FootSec {
        width: 100%;
    }    
    .FootSecTitle {
        padding-top: 0px;
    }
}

@media (min-width: 576px) {
    .LogoSec {
        width: 100%;
    }
    .FootSec {
        width: 33.33%;
    }    
    .FootSecTitle {
        padding-top: 0px;
    }
}

@media (min-width: 768px) {}

@media (min-width: 992px) {
    .LogoSec {
        width: 30%;
    }
    .FootSec {
        width: 23.33%;
    }    
    .FootSecTitle {
        padding-top: 30px;
    }
}