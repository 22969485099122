@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

*, body {
    font-family: 'Noto Sans', sans-serif !important;
    margin: 0;
    padding: 0;
}

.Heading {
    font-family: 'Roboto Slab', serif !important;
}

.Header {
    width: 100%;
    background: #e64f22;
    padding: 0;
    position: sticky;
    z-index: 999;
    top: 0;
}

.Container {
    width: 1140px;
    margin: 0 auto;
}

.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.CustoSideNav {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 #34343412;
    padding: 0 0 10px 0;
}

.CustoSideNav ul {
    margin: 0;
    padding: 0;
    width: 100%;
}

.CustoSideNav ul li {
    list-style: none;
}

.CustoSideNav ul li a {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #343434 !important;
    background-color: #fff;
    opacity: .8;
    cursor: pointer;
    text-decoration: none;
    transition: all .3s ease-in-out;
}

.CustoSideNav ul li button {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #343434 !important;
    background-color: #fff;
    opacity: .8;
    cursor: pointer;
    text-decoration: none;
    transition: all .3s ease-in-out;
    border: none;
}

.CustoSideNav ul li a svg, .CustoSideNav ul li button svg {
    color: #e64f22;
    width: 20px;
    height: auto;
    margin-right: 15px;
}

.CustoSideNav ul li a:hover, .CustoSideNav ul li button:hover {
    opacity: 1;
    background-color: #e64f222e;
}

.CustoSideNav ul li.ListHead {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #c9c9c9;
    margin-bottom: 15px;
    padding: 20px;
}

.CustoSideNav ul li.ListHead img {
    width: 60px;
    height: 60px;
    margin-bottom: 0px;
}

.CustoSideNav ul li.ListHead p {
    font-size: 18px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: #343434;
    margin: 0;
}

.CustoSideNav ul li.ListHead .CusAvatar {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    background: #e64f22;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CustoSideNav ul li.ListHead .CusAvatar span {
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: 'Roboto Slab', serif !important;
    padding: 0 1px;
    color: #fff;
}