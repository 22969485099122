.MainDiv {
    width: 100%;
}

.PageTitleWrap {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ebebeb;
    box-shadow: 0 3px 10px rgb(62 85 120 / 7%);
    padding: 1rem 1.8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.PageTitle {
    margin: 0;
    color: #505050;
    font-size: 18px;
    font-weight: 500;
}

.MainCard {
    padding: 2rem 2.25rem;
    border-radius: 6px;
    position: relative;
    background: #fff;
    border: none;
    box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    margin: 40px 1.8rem 40px;
}

.AddUserMain {
    width: 800px;
    padding: 15px;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: .875rem;
}

.FormGroup {
    width: 48%;
    padding-bottom: 20px;
    font-size: .875rem;
    position: relative;
}

.FormLabel {
    font-size: 13px;
    line-height: 30px;
    font-weight: 600;
    color: #333;
    margin: 0;
    text-transform: uppercase;
}

.FormControl {
    display: block;
    width: 100%;
    min-width: 150px;
    height: 38px;
    padding: 0.375rem 0.4rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.FormControlTextArea {
    display: block;
    width: 100%;
    min-width: 150px;
    height: 100px;
    resize: none;
    padding: 0.375rem 0.4rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.FormControl:focus {
    outline: none;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 0;
}

.FormButtonArea {
    width: 100%;
    padding-top: 25px;
}

.CancelBU {
    color: #e64f22;
    padding: 12px 15px;
    text-decoration: none;
    background: #fff;
    border: 1px solid #e64f22;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    margin: 0 20px 0 0;
    width: 110px;
    transition: all .3s ease-in-out;
}

.CancelBU:hover {
    background: #e64f22;
    border: 1px solid #e64f22;
    color: #fff;
}

.SubmitBU {
    color: #e64f22;
    padding: 12px 15px;
    text-decoration: none;
    background: #e64f222e;
    border: 1px solid #fadfd7;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    margin: 0 20px 0 0;
    width: 110px;
    transition: all .3s ease-in-out;
}

.SubmitBU:hover {
    background: #e64f22;
    border: 1px solid #e64f22;
    color: #fff;
}

.ActiveBU {
    background-color: #40bf0c !important;
    border-color: #40bf0c !important;
    transition: all .3s ease-in-out;
}

.ActiveBU:hover {
    background-color: #329909 !important;
    border-color: #329909 !important;
}

.ArchiveBU {
    background-color: #7c4836 !important;
    border-color: #7c4836 !important;
    transition: all .3s ease-in-out;
}

.ArchiveBU:hover {
    background: #5c3324 !important;
    border-color: #5c3324 !important;
}

.ActionDiv {
    display: flex;
}

.ActionDiv a {
    color: #424242;
    margin-right: 10px;
}

.ActionDiv a svg,
.ActionDiv button {
    width: 20px;
    height: 20px;
}

.ActionDiv button {
    width: 20px;
    min-width: 20px;
    height: 20px;
    padding: 0;
    color: #424242;
    margin-right: 16px;
    transition: all .3s ease;
}

.ActionDiv button:hover {
    color: #e64f22;
}

.ActionDiv a:hover {
    color: #e64f22;
}

.ActionDivCancel button:hover {
    color: #FFFFFF;
}

.ActionDivCancel a:hover {
    color: #FFFFFF;
}

.ListBadge {
    font-size: 11px;
    font-weight: 600;
    background: #d9d9d9;
    color: #8b8b8b;
    padding: 5px 8px;
    line-height: 11px;
    border-radius: 4px;
}

.ListBadge.ListBadgeActive {
    background: #e8fff3;
    color: #47be7d;
}

.BackLinkNew {
    color: #8999aa;
    font-size: 14px;
    font-weight: 500;
    display: block;
    position: relative;
    transition: all .2s ease-in-out;
    text-decoration: none;
    margin: 40px 1.8rem -30px;
}

.BackLinkNew svg {
    width: 18px;
    margin: -1px 3px 0 0;
}

.BackLinkNew:hover{
    color: #e64f22;
    text-decoration: none;
}

.FilterSec {
    width: 220px;
    padding: 10px 20px;
}

.FilterCard {
    width: 100%;
    padding: 10px 0;
}

.FilterCard label {
    font-size: 13px;
    color: #505050;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 4px;
}

.FilterBuArea {
    display: flex;
    justify-content: space-between;
}

.FilterBuArea button {
    width: 46%;
    background: #fadfd7;
    border: none;
    text-align: center;
    border-radius: 4px;
    display: block;
    color: #e34e22;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 6px 8px 6px 8px;
}

.BodyHWithIcon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.BodyHWithIcon p {
    font-size: 13px;
    color: #333;
    margin: 0;
}

.BodyHeadIcon {
    display: flex;
}

.BodyHeadIcon button, .BodyHeadIcon a {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: none;
    margin-left: 10px;
    color: #333;
    opacity: .8;
    transition: all .3s ease-in-out;
}

.BodyHeadIcon button svg, .BodyHeadIcon a svg {
    width: 18px;
    height: 18px;
}

.BodyHeadIcon button:hover, .BodyHeadIcon a:hover {
    color: #e34e22;
    opacity: 1;
}

.MondalBodyDeRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 0 20px 0;
    border-bottom: 1px solid #f4f4f4;
}

.ModalDeBigSec {
    width: 45%;
}

.ModalDeFullSec {
    width: 100%;
}

.StepBoxTitle2 {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    margin: 0 0 10px 0;
    display: flex;
    justify-content: space-between;
}

.StepBoxTitle2 a,
.StepBoxTitle2 span {
    font-size: 12px;
    color: #999;
    text-decoration: none;
}

.StepBoxTitle2 a:hover,
.StepBoxTitle2 span:hover {
    color: #e34e22;
}

.StepBoxTitle2 a svg,
.StepBoxTitle2 span svg {
    width: 12px;
    height: 12px;
    margin-left: 3px;
}

.SummeryListSec {
    width: 100%;
    margin: 0;
    padding: 0;
}

.SummeryListSec li {
    list-style: none;
    display: flex;
    padding: 5px 0;
}

.SummeryHeadTitle {
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #7E8299;
    min-width: 150px;
}

.SummeryHeadText {
    margin: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #3F4254;
    width: 200px;
}

.ImageRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
}

.ImageRow .imgDiv {
    width: 100px;
    height: 100px;
    padding: 6px;
    border-radius: 4px;
    overflow: hidden;
}

.ImageRow .imgDiv img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.BookingNote {
    width: 100%;
    padding: 20px 0 20px 0;
}

.BookingNote .Title {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    margin: 0 0 10px 0;
}

.BookingNote .Text {
    font-size: 13px;
    color: #333;
    margin: 0;
}

.MondalBodyDeRow2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0 20px 0;
}

.FullTitle {
    font-size: 14px;
    width: 100%;
    text-align: center;
    background-color: #e64f2236;
    color: #e64f22;
    padding: 10px 0;
    font-weight: 600;
}

.PickName {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin-bottom: 10px;
}

.DetailsList {
    font-size: 14px;
    display: flex;
    margin: 0 0 10px 0;
}

.DetailsList svg {
    width: 18px;
    height: 18px;
    margin-right: 12px;
    color: #e64f22;
    margin-top: 3px;
}

.TableSec,
.TableSec table {
    width: 100%;
    padding-bottom: 20px;
}

.TableSec .TableHead {
    padding: 12px 14px;
    background: #f5f5f5;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
    border: 1px solid #e7e7e7;
}

.TableSec .TableCon {
    padding: 12px 14px;
    background: #fff;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #626262;
    border: 1px solid #e7e7e7;
}

.EstiAmmo {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.EstiAmmo ul {
    margin: 0;
    padding: 0;
}

.EstiAmmo ul li {
    margin: 0;
    padding: 8px 0 0 0;
    list-style: none;
    display: flex;
}

.EstiTitle {
    font-size: 15px;
    font-weight: 600;
    color: #333;
    margin: 0;
    width: 160px;
    text-align: right;
}

.EstiInput {
    font-size: 15px;
    font-weight: 400;
    color: #666;
    margin: 0;
    width: 80px;
    text-align: right;
}

.StatusBU {
    color: #e64f22 !important;
    padding: 5px 10px !important;
    text-decoration: none !important;
    background: #e64f222e !important;
    border-radius: 6px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    margin: 0 0 0 0 !important;
    max-width: 100%;
}

.StatusBU.DisaBU {
    background: #ebebeb !important;
    color: #333 !important;
    cursor: auto;
}

.FullDiv {
    width: 100%;
}

.PopupTitle {
    font-size: 16px;
    color: #5e5e5e;
    margin: 0 0 25px 0;
}

.FormGroupFull {
    width: 100%;
}