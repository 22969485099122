.MainDiv {
    width: 100%;
}

.PageTitleWrap {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ebebeb;
    box-shadow: 0 3px 10px rgb(62 85 120 / 7%);
    padding: 1rem 1.8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.PageTitle {
    margin: 0;
    color: #505050;
    font-size: 18px;
    font-weight: 500;
}

.MainCard {
    padding: 2rem 2.25rem;
    border-radius: 6px;
    position: relative;
    background: #fff;
    border: none;
    box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    margin: 40px 1.8rem 40px;
}

.AddUserMain {
    width: 800px;
    padding: 15px;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: .875rem;
}

.FormGroup {
    width: 48%;
    padding-bottom: 20px;
    font-size: .875rem;
    position: relative;
}

.FormLabel {
    font-size: 13px;
    line-height: 30px;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.FormControl {
    display: block;
    width: 100%;
    min-width: 150px;
    height: 38px;
    padding: 0.375rem 0.4rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.FormControl:focus {
    outline: none;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 0;
}

.FormButtonArea {
    width: 100%;
}

.CancelBU {
    padding: 12px 30px;
    background-color: #fff;
    margin: 15px 15px 0 0;
    font-size: 14px;
    width: auto;
    font-weight: 600;
    line-height: 14px;
    border-radius: 0.5rem;
    color: #006;
    border: 1px solid #006;
    text-align: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
    outline: none;
    display: inline-block;
}

.CancelBU:hover {
    background-color: #006;
    color: #fff;
}

.SubmitBU {
    padding: 12px 30px;
    background-color: #006;
    margin: 15px 15px 0 0;
    font-size: 14px;
    width: auto;
    font-weight: 600;
    line-height: 14px;
    border-radius: 0.5rem;
    color: #fff;
    text-align: center;
    border: 1px solid #006;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
    outline: none;
}

.SubmitBU:hover {
    background-color: #010133;
    border: 1px solid #010133;
}

.ActiveBU {
    background-color: #40bf0c !important;
    border-color: #40bf0c !important;
    transition: all .3s ease-in-out;
}

.ActiveBU:hover {
    background-color: #329909 !important;
    border-color: #329909 !important;
}

.ArchiveBU {
    background-color: #7c4836 !important;
    border-color: #7c4836 !important;
    transition: all .3s ease-in-out;
}

.ArchiveBU:hover {
    background: #5c3324 !important;
    border-color: #5c3324 !important;
}

.ActionDiv {
    display: flex;
}

.ActionDiv a {
    color: #424242;
    margin-right: 10px;
}

.ActionDiv a svg,
.ActionDiv button {
    width: 20px;
    height: 20px;
}

.ActionDiv button {
    width: 20px;
    min-width: 20px;
    height: 20px;
    padding: 0;
    color: #424242;
    margin-right: 10px;
    transition: all .3s ease;
}

.ActionDiv button:hover {
    color: #e64f22;
}

.ListBadge {
    font-size: 11px;
    font-weight: 600;
    background: #d9d9d9;
    color: #8b8b8b;
    padding: 5px 8px;
    line-height: 11px;
    border-radius: 4px;
}

.ListBadge.ListBadgeActive {
    background: #e8fff3;
    color: #47be7d;
}

.BackLinkNew {
    color: #8999aa;
    font-size: 14px;
    font-weight: 500;
    display: block;
    position: relative;
    transition: all .2s ease-in-out;
    text-decoration: none;
    margin: 40px 1.8rem -30px;
}

.BackLinkNew svg {
    width: 18px;
    margin: -1px 3px 0 0;
}

.BackLinkNew:hover{
    color: #e64f22;
    text-decoration: none;
}

.FilterSec {
    width: 220px;
    padding: 10px 20px;
}

.FilterCard {
    width: 100%;
    padding: 10px 0;
}

.FilterCard label {
    font-size: 13px;
    color: #505050;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 4px;
}

.FilterBuArea {
    display: flex;
    justify-content: space-between;
}

.FilterBuArea button {
    width: 46%;
    background: #fadfd7;
    border: none;
    text-align: center;
    border-radius: 4px;
    display: block;
    color: #e34e22;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 6px 8px 6px 8px;
}