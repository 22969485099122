@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

*, body {
    font-family: 'Noto Sans', sans-serif !important;
    margin: 0;
    padding: 0;
}

.Heading {
    font-family: 'Roboto Slab', serif !important;
}

.Container {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.BannerSec{
    width: 100%;
    overflow: hidden;
    display: block;
    position: relative;
    background-color: #000;
}
.BannerSec img{
    width: 100%;
    /* opacity: 0.4; */
    display: block;
    height: 180px;
    object-fit: cover;
    object-position: center;
}
.BannerTitleCU{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 0%;
    transform: translate(0, -50%);
    padding: 10px 35px;
    z-index: 9;
}
.BannerSec h1{
    color: #fff;
    font-size: 58px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: inline;
    text-shadow: 0 0 10px #00000047
}
.We_AreSec{
    /* margin: 0 auto; */
    text-align: center;
    padding-top: 70px;
    padding-bottom: 80px;
    /* padding-left: 150px;
    padding-right: 150px; */
}
.head5{
    margin-bottom: 30px;
}
.We_AreTitle{
    font-size: 28px;
    text-align: center;
    font-weight: 900;
    color: #262626;
    margin-bottom: 30px;
}
.We_AreTitle span{
    color: #e64f22;
    border-bottom: 3px solid #e64f22;
}
.We_AreSmallTitle{
    width: 85%;
    margin: auto;
    font-size: 16px;
    font-weight: 400;
    color: #4a4a4a;
}
.What_ServeSec{
    padding-top: 70px;
    padding-bottom: 80px;
    background-color: #f3f3f3;
}
.white_box{
    background-color: #fff;
    padding: 30px 10px 30px 0px;
    margin: 15px;
    text-align: center;
    border-radius: 5px;
}
.white_box img {
    width: 70px;
    margin-bottom: 12px;
    height: 70px;
    object-fit: contain;
}
.white_box h5 {
    font-weight: 700;
    font-size: 14px;
    color: #858585;
}
.sec2bxin{
    display: flex;
    padding: 70px 40px 40px;
    position: relative;
    width: 100%;
}
.sec2bxin a{
    text-decoration: none;
    width: 97%;
    display: block;
    min-height: 420px;
    padding: 40px;
    background: #fff;
    -webkit-box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.18);
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.18);
}
.sec2bxin a img {
    width: 90px;
    display: block;
}
.sec2bxin a h3 {
    font-weight: 800;
    font-size: 21px;
    margin-bottom: 20px;
    transition: all 0.3s;
    margin-top: 20px;
    color: #e64f22;
}
.sec2bxin a p {
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    overflow: hidden;
    color: #858585;
}

.contactpg{
    width: 100%;
    display: flex;
    padding: 70px 0;
    background-color: #fff;
}
.contactpg h2{
    font-size: 42px;
    font-weight: 800;
    color: #e64f22;
    margin-bottom: 30px;
}
.form_group{
    margin-bottom: 15px;
}
.jobdescription label{
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 400;
}
.jobdescription label span{
    color: #e64f22;
}
.jobdescription input.form_control, .jobdescription textarea.form_control{
    background-color: #fff;
    border-radius: 0px;
    width: 100%;
    height: 42px;
    border: 0px;
    margin-bottom: 20px;
    font-weight: 400;
    box-shadow: none;
    border: 1px solid #D5D5D5;
    display: table;
    color: #000;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
}
.col-sm-6 {
    float: left;
    flex: 0 0 auto;
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
}
.jobdescription .col-sm-12{
    display: table;
    width: 100%;
}
.jobdescription textarea.form_control {
    min-height: 120px;
    resize: none;
}
.SubButton{
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 28px;
     text-transform: uppercase; 
    height: 48px;
    border-radius: 8px;
    border: none;
    background: #e64f22;
    margin-top: 20px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.SubButton::before {
    content: "";
    position: absolute;
    top: -180%;
    left: -100%;
    width: 150%;
    height: 300%;
    opacity: 0;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    background: hsla(0,0%,100%,0);
    background: -webkit-gradient(linear,left top,right top,from(hsla(0,0%,100%,0)),color-stop(70%,hsla(0,0%,100%,0)),color-stop(74%,hsla(0,0%,100%,.4)),color-stop(76%,hsla(0,0%,100%,.2)),color-stop(77%,hsla(0,0%,100%,0)),color-stop(79%,hsla(0,0%,100%,.1)),color-stop(92%,hsla(0,0%,100%,.5)),to(hsla(0,0%,100%,0)));
    background: -webkit-linear-gradient(left,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
    background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
}

.SubButton:hover:before {
    opacity: 1;
    top: -160%;
    left: 15%;
    -webkit-transition-property: left,top,opacity;
    transition-property: left,top,opacity;
    -webkit-transition-duration: .7s,.7s,.15s;
    transition-duration: .7s,.7s,.15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

.contactpg h1 {
    position: relative;
    padding: 21px 80px 21px 24px;
    width: 100%;
    display: block;
    color: #fff;
    font-size: 24px;
    margin: 0px;
    letter-spacing: 0px;
    background-color: #e64f22;
}
.contactpg .icon_wrapper {
    width: 80px;
    bottom: 0px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 5;
    overflow: hidden;
}
.contactpg .icon {
    width: 60px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    position: absolute;
    color: #fff;
    background-color: #002e5b;
    display: flex;
    align-items: center;
    justify-content: center;
}
.address_Area {
    margin-top: 0px;
    font-size: 18px;
    line-height: 32px;
    display: block;
    clear: both;
    background-color: #f7f7f7;
    padding: 20px;
}
.Addstitle{
    color: #e64f22;
    font-size: 24px;
    font-weight: 800;
}
.addsMS{
    align-items: flex-start;
    color: #000;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    padding: 10px 0;
    text-decoration: none;
    text-shadow: none;
    transition: all .3s ease-in-out;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.addsMS svg{
    color: #e64f22;
    height: 18px;
    margin-right: 10px;
    margin-top: 2px;
    min-width: 18px;
    padding-top: 0;
    width: 18px;
}
.addsMS:hover{
    color: #002e5b;
}

.col-sm-7{
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
}
.map img{
    width: 100%;
    height: 450px;
    object-fit: cover;
    object-position: center;
}

.ContactFromRow {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.ContactFormBigSec {
	width: 55%
}

.ContactFormSmallSec {
	width: 40%;
    padding-top: 110px;
}

.ContactFormBigSec form {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between
}

.FormFieldHalf {
	width: 48%;
}

.FormFieldFull {
	width: 100%
}

.Offers {
    width: 100%;
    padding: 70px 0;
    position: relative;
}

.OffersTitle {
    font-size: 28px;
    text-align: center;
    font-weight: 900;
    color: #262626;
    margin-bottom: 5px;
}

.OffersTitleSM {
    font-size: 14px;
    text-align: center;
    color: #858585;
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 20px;
}

.OffersTitleSM:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background: #e64f22;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.OffersHalf {
    width: 50%;
    padding: 15px;
}

.OffersHalfUnder {
    width: 100%;
    display: flex;
    align-items: center;
    background: #f7f7f7;
    padding: 25px 30px;
}

.OffersHalf img {
    width: 80px;
    height: auto;
    margin-right: 30px;
}

.OffersBG {
    position: absolute;
    bottom: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.OfferCardTitle {
    font-size: 18px;
    font-weight: 700;
    color: #383838;
    margin-bottom: 12px;
}

.OfferCardText {
    font-size: 14px;
    font-weight: 400;
    color: #5c5c5c;
    margin-bottom: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.Testimonial .item {
    background-color: #fff;
    box-shadow: 0px 0px 4px #ccc;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 10px;
}

.TestiHead {
    display: flex;
    align-items: center;
}

.TestiHead img {
    width: 60px !important;
    height: 60px;
    margin-right: 25px;
}

.TestiName {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 12px;
}

.TestiDes {
    font-size: 14px;
    color: #9EA1AB;
    font-weight: 400;
}

.TestiCon {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
}

@media (min-width: 360px) {
    .BannerSec h1{
        font-size: 38px;
    }
    .BannerSec img{
        height: 180px;
    }
    .ContactFormBigSec {
        width: 100%
    }    
    .ContactFormSmallSec {
        width: 100%;
        padding-top: 20px;
    }
}

@media (min-width: 576px) {
    .BannerSec h1{
        font-size: 38px;
    }
    .BannerSec img{
        height: 250px;
    }
}

@media (min-width: 768px) {
    .BannerSec h1{
        font-size: 48px;
    }
    .BannerSec img{
        height: 300px;
    }
    .ContactFormBigSec {
        width: 55%
    }    
    .ContactFormSmallSec {
        width: 40%;
        padding-top: 110px;
    }
}

@media (min-width: 992px) {
    .BannerSec h1{
        font-size: 58px;
    }
    .BannerSec img{
        height: 350px;
    }
}