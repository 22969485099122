@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

*, body {
    font-family: 'Noto Sans', sans-serif !important;
    margin: 0;
    padding: 0;
}

.Heading {
    font-family: 'Roboto Slab', serif !important;
}

.Header {
    width: 100%;
    background: #e64f22;
    padding: 0;
    position: sticky;
    z-index: 999;
    top: 0;
}

.Container {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.HeadRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeadLogo {
    width: 200px;
    height: auto;
    padding: 17px 0px;
}

.Menu {
    display: flex;
    align-items: center;
}

.Menu ul {
    display: flex;
    margin: 0;
}

.Menu ul li {
    list-style: none;
}

.Menu ul li a {
    font-size: 14px;
    line-height: 60px;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 15px;
    text-transform: uppercase;
    position: relative;
    transition: all .3s ease-in-out;
}

.Menu ul li a:hover {
    color: #002e5b;
}

.Menu ul li a::after {
    content: '';
    width: 0%;
    height: 5px;
    background: #002e5b;
    bottom: 0;
    left: 0;
    position: absolute;
    transition: all .3s ease-in-out;
}

.Menu ul li a:hover::after {
    width: 100%;
}

.HeadLoginBU {
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff !important;
    padding: 10px 28px;
    text-transform: uppercase;
    height: 48px;
    border-radius: 8px;
    border: none;
    background: #002e5b;
    margin-left: 20px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.HeadLoginBU:before {
    content: "";
    position: absolute;
    top: -180%;
    left: -100%;
    width: 150%;
    height: 300%;
    opacity: 0;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    background: hsla(0,0%,100%,0);
    background: -webkit-gradient(linear,left top,right top,from(hsla(0,0%,100%,0)),color-stop(70%,hsla(0,0%,100%,0)),color-stop(74%,hsla(0,0%,100%,.4)),color-stop(76%,hsla(0,0%,100%,.2)),color-stop(77%,hsla(0,0%,100%,0)),color-stop(79%,hsla(0,0%,100%,.1)),color-stop(92%,hsla(0,0%,100%,.5)),to(hsla(0,0%,100%,0)));
    background: -webkit-linear-gradient(left,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
    background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
}

.HeadLoginBU:hover:before {
    opacity: 1;
    top: -160%;
    left: 15%;
    -webkit-transition-property: left,top,opacity;
    transition-property: left,top,opacity;
    -webkit-transition-duration: .7s,.7s,.15s;
    transition-duration: .7s,.7s,.15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

.HeadTrackBU {
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #002e5b;
    padding: 10px 28px;
    text-transform: uppercase;
    height: 48px;
    border-radius: 8px;
    border: none;
    background: #fff;
    margin-left: 20px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    border: 0px solid #002e5b;
    transition: all .3s ease-in-out;
}

.HeadTrackBU:hover {
    color: #fff;
    background: #002e5b;
}

.ModalBody {
    width: 100%;
    padding: 40px 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
}

.PopupHeading {
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    color: #262626;
    margin-bottom: 30px;
}

.FormSec {
    width: 500px;
    background: #FFFFFF;
    border: 1px solid #E64F22;
    border-radius: 0px;
    padding: 3px 3px 3px 10px;
    display: flex;
}

.FormSec input {
    width: 100%;
    border: none;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #858585;
}

.FormSec input:focus {
    outline: none;
}

.FormSec button {
    font-size: 15px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 6px 1px;
    text-transform: uppercase;
    height: 40px;
    border-radius: 0px;
    border: none;
    background: #E64F22;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    /* margin: 80px auto 0 auto; */
    width: 100px;
}

.FormSec button::before {
    content: "";
    position: absolute;
    top: -180%;
    left: -100%;
    width: 150%;
    height: 300%;
    opacity: 0;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    background: hsla(0,0%,100%,0);
    background: -webkit-gradient(linear,left top,right top,from(hsla(0,0%,100%,0)),color-stop(70%,hsla(0,0%,100%,0)),color-stop(74%,hsla(0,0%,100%,.4)),color-stop(76%,hsla(0,0%,100%,.2)),color-stop(77%,hsla(0,0%,100%,0)),color-stop(79%,hsla(0,0%,100%,.1)),color-stop(92%,hsla(0,0%,100%,.5)),to(hsla(0,0%,100%,0)));
    background: -webkit-linear-gradient(left,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
    background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
}

.FormSec button:hover:before {
    opacity: 1;
    top: -160%;
    left: 15%;
    -webkit-transition-property: left,top,opacity;
    transition-property: left,top,opacity;
    -webkit-transition-duration: .7s,.7s,.15s;
    transition-duration: .7s,.7s,.15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

.ModalCloseBU,
.ModalCloseBU:hover {
    position: absolute;
    top: -17px;
    right: -21px;
    background: #ffffff;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    cursor: pointer;
    border: none;
}

.ModalCloseBU svg {
    width: 22px;
    opacity: .8;
}

.Tabs {
    width: 100%;
    display: flex;
}

.TabCard {
    display: flex;
    align-items: center;
    color: #545454;
    padding: 5px 10px;
    margin-bottom: -1px;
    border: 1px solid #E64F22;
    border-bottom: 0;
    background-color: #E64F22;
    color: #fff;
}

.TabCard.Active {
    border: 1px solid #E64F22;
    border-bottom: 0;
    background-color: #fff;
    color: #E64F22;
}

.TabCard svg {
    width: 16px;
    margin-right: 6px;
    height: 16px;
}

.TabCard p {
    margin: 0;
    font-size: 14px;
}

.ErrorM {
    width: 100%;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    color: #f40;
    margin: 0 0 0 0;
}

.UserDropMenu li {
    padding: 0;
}

.UserDropMenu li {
    padding: 0;
}

.UserProfile,
.UserProfile:hover {
    padding: 10px 20px;
    font-size: 15px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #505050;
    font-weight: 500;
}

.UserProfile svg {
    width: 18px;
    height: 18px;
    margin-right: 12px;
}

.UserLogoutBU {
    padding: 10px 20px;
    font-size: 15px;
    text-decoration: none;
    display: flex;
    align-items: center;
    border: none;
    color: #505050;
    font-weight: 500;
    width: 100%;
    background: transparent;
}

.UserLogoutBU svg {
    width: 18px;
    height: 18px;
    margin-right: 12px;
}

.UserProfileDropBU {
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    width: 45px;
    height: 45px;
    background: #fff;
    text-align: center;
    font-weight: 600;
    color: #333333;
    margin: 0;
    font-family: 'Roboto Slab', serif !important;
}

.UserDropdownList a {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding: 5px 0;
    font-size: 16px;
    font-weight: 500;
    color: #343434 !important;
    background-color: #fff;
    opacity: .8;
    cursor: pointer;
    text-decoration: none;
}

.UserDropdownListLogout {
    padding-top: 11px;
    padding-bottom: 11px;
}

.UserDropdownList a svg,
.UserDropdownListLogout svg {
    color: #e64f22;
    width: 20px;
    height: auto;
    margin-right: 15px;
}

@media (max-width: 991px) {
    .UserProfileDropBU {
        display: flex;
    }
    .Menu {
        display: none;
    }
}