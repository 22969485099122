@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

*, body {
    font-family: 'Noto Sans', sans-serif !important;
    margin: 0;
    padding: 0;
}

.Heading {
    font-family: 'Roboto Slab', serif !important;
}

.Container {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.MainDivCustomer {
    padding-top: 100px;
    padding-bottom: 60px;
    width: 100%;
    position: relative;
}

.MainDivCustomer:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    background-image: linear-gradient(#fff 40%, rgb(0 0 0 / 0%));
    z-index: 1;
}

.BackGroundGra {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: .7;
    top: 0;
    z-index: -1;
    object-fit: cover;
}

.BookingCard {
    padding: 10px 0;
    width: 100%;
}

.UserProCard {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    position: relative;
    width: 80%;
    margin: auto;
}

.UserProCard2 {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    position: relative;
    width: 100%;
    margin: auto;
}

.BookingCardUnderTop {
    align-items: center;
    position: relative;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}

.BookingCardUnderTop img {
    height: auto;
    margin-right: 30px;
    width: 160px;
}

.BookingCarDeSec {
    position: relative;
    z-index: 1;
    width: 42%;
}

.BookingCarDeTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    color: #2a2a2a;
    margin: 0 0 4px 0;
    display: flex;
    align-items: center;
}

.BookingCarDeTitle span.Upcoming {
    font-size: 12px;
    font-weight: 500;
    background: #006;
    color: #fff;
    padding: 6px 12px;
    border-radius: 20px;
    height: auto;
    line-height: 14px;
    margin-left: 5px;
}

.BookingCarDeTitle span.Completed {
    font-size: 12px;
    font-weight: 500;
    background: #008000;
    color: #fff;
    padding: 6px 12px;
    border-radius: 20px;
    height: auto;
    line-height: 14px;
    margin-left: 5px;
}

.BookingCarDeSTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #959595;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
}

.BookingCarDeSTitle svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.BookingCarFeatured {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.BookingCarFeatured span {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #5a5a5a;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.BookingCarFeatured span:last-child {
    margin-right: 0;
}

.BookingCarFeatured span img {
    width: 16px;
    height: 16px;
    margin-right: 7px;
}

.BookCarInfoLink {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #006 !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.BookCarInfoLink::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 1px;
    bottom: -3px;
    left: 0px;
    background: #006;
    transition: all .3s ease-in-out;
}

.BookCarInfoLink:hover {
    color: #006 !important;
}

.BookCarInfoLink:hover::after {
    width: 92px;
}

.BookingCardUnderRow {
    width: Calc(100% - 220px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.BookCarViewBU {
    width: auto;
    font-size: 13.5px;
    font-weight: 500;
    padding: 12px 14px;
    background-color: #7C4836;
    color: #fff;
    letter-spacing: .5px;
    border: none;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
}

.BookCarViewBU span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
}

.BookCarViewBU span svg {
    width: 18px;
    height: 18px;
    margin-left: 11px;
    border: 1px solid #fff;
    border-radius: 50%;
}

.BookingCardUnder.Open .BookCarViewBU span svg {
    transform: rotate(180deg);
}

.BookCarViewBU:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 0;
}

.BookCarViewBU:hover:after {
    width: 100%;
    left: 0;
}

.BookingCardUnderBottom {
    width: 100%;
    background: #f3f3f3;
    border-radius: 8px;
    padding: 20px;
    margin-top: 50px;
    display: none;
}

.BookingCardUnder.Open .BookingCardUnderBottom {
    display: block;
}

.BookingCardUnderBottomRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.BookingDeConSec {
    width: 50%;
}

.SmallSecSideTitle {
    margin: 0 0 15px 0;
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

.SmallCardDESec {
    width: 100%;
    margin-bottom: 25px;
}

.SmallCardDESec2 {
    width: 100%;
    margin-bottom: 6px;
}

.SmallCardDESec:last-child {
    margin-bottom: 0;
}

.SmallCardDESecHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.SmallCardDESecTitle {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.SmallCardDEList {
    margin: 0 0 5px 0;
    font-size: 13px;
    font-weight: 500;
    color: #606060;
}

.SideCarDeBodyRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SmallCardTaxSec {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    padding: 12px 0;
}

.SmallCardTaxTitle {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.SmallCardTaxAmm {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    color: #333;
}

.SmallCardTotal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 15px;
}

.SmallCardTOtalAmm {
    margin: 0;
    font-size: 25px;
    font-weight: 700;
    color: #333;
    position: relative;
}

.SmallCardTOtalAmm::before {
    content: '$';
    position: absolute;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    left: -15px;
    top: 4px;
}

.NofileSec {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 450px;
}

.NofileSecUnder {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    opacity: .4;
}

.NofileSecUnder img {
    width: 120px;
    margin-bottom: 40px;
}

.NofileSecUnder p {
    width: 100%;
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 0;
}

.UserHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.UserProName {
    font-size: 36px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    width: 100%;
}

.UserProDe {
    width: 100%;
    padding-top: 0px;
    display: flex;
    flex-wrap: wrap;
}

.UserProDeList {
    font-size: 16px;
    font-weight: 600;
    color: #3F4254;
    line-height: 22px;
    width: 50%;
    margin: 0;
    padding: 10px 0;
    display: flex;
}

.UserProDeList span {
    font-weight: 500;
    color: #7E8299;
    width: 120px;
    display: inline-block;
}

.SideEditBU {
    border: 1px solid #e64f22;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    padding: 8px 15px;
    color: #ffffff;
    background: #e64f22;
    transition: all .3s ease-in-out;
    white-space: nowrap;
    text-decoration: none;
    margin-left: 15px;
}

.SideEditBU:hover {
    color: #fff;
    background: #cb4219;
    border: 1px solid #cb4219;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
    font-size: .875rem;
    justify-content: space-between;
}

.FormGroup {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 48%;
}

.FormGroupFull {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 100%;
}

.FormGroupOneThird {
    font-size: .875rem;
    padding-bottom: 20px;
    position: relative;
    width: 31%;
}

.FormLabel {
    color: #333;
    font-size: 13px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
}

.FormControl {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #495057;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    height: 38px;
    line-height: 1.5;
    min-width: 150px;
    padding: 0.375rem 0.4rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
}

.FormControl:focus {
    outline: none;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 4px;
}

.MyProUpdateBU {
    width: auto;
    font-size: 13.5px;
    font-weight: 500;
    padding: 12px 14px;
    background-color: #008000;
    color: #fff;
    letter-spacing: .5px;
    border: none;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
}

.MyProUpdateBU span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
}

.MyProUpdateBU:after {
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 0;
}

.MyProUpdateBU:hover:after {
    width: 100%;
    left: 0;
}

.MyProBUSec {
    margin-top: 25px;
}

.CusRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.CustomerSideBar {
    width: 300px;
}

.CustomerDeSec {
    width: Calc(100% - 400px);
}

.MyBookConHead {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.MyBookConDeSec {
    width: 100%;
    padding: 15px 0;
    border-top: 1px solid #c9c9c9;
}

.ActionBU {
    background-color: #e64f22 !important;
    color: #fff !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 110px !important;
    padding: 8px 5px !important;
    margin: 0 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.ActionMenuSec ul {
    padding: 8px 0 !important;
}

.ActionMenuSec ul li {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
}

.MoreDropBU {
    width: 50px;
    height: 40px;
    background-color: transparent;
    display: none;
    align-items: center;
    justify-content: center;
    margin-right: -20px;
}

.UserDropdownList a {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding: 5px 0;
    font-size: 16px;
    font-weight: 500;
    color: #343434 !important;
    background-color: #fff;
    opacity: .8;
    cursor: pointer;
    text-decoration: none;
}

@media (min-width: 360px) {
    .UserProDeList {
        width: 100%;
        font-size: 14px;
    }
    .UserProDeList span {
        min-width: 120px;
    }
    .UserProCard {
        width: 100%;
    }
    .UserProName {
        font-size: 22px;
    }
}

@media (min-width: 768px) {
    .UserProCard {
        width: 90%;
    }
    .UserProName {
        font-size: 28px;
    }
    /* .UserProDeList {
        font-size: 16px;
    } */
    .UserProDeList span {
        width: 120px;
    }
}

@media (min-width: 992px) {
    /* .UserProDeList {
        width: 50%;
    } */
    .CustomerSideBar {
        width: 260px;
    }    
    .CustomerDeSec {
        width: Calc(100% - 280px);
    }
}

@media (min-width: 1200px) {
    .UserProCard {
        width: 80%;
    }
    .UserProName {
        font-size: 18px;
    }
    .CustomerSideBar {
        width: 300px;
    }    
    .CustomerDeSec {
        width: Calc(100% - 350px);
    }
}

.UserProDeList2{
    width: 100%;
}

.UserProDeList2 span{
    width: auto;
}

/* .SideEditBU2{
    width: 109px;
    margin-right: 3px;
}

.SideEditBU3{
    width: 161px;
} */

@media (max-width: 991px) {
    .CustomerSideBar {
        display: none;
    }
    .CustomerDeSec {
        width: 100%;
    }
    .MainDivCustomer {
        padding-top: 50px;
        padding-bottom: 40px;
    }
}

@media (max-width: 767px) {
    .SideEditBU {
        display: none;
    }
    .MoreDropBU {
        display: flex;
    }
}