@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

*, body {
    font-family: 'Noto Sans', sans-serif !important;
    margin: 0;
    padding: 0;
}

.Heading {
    font-family: 'Roboto Slab', serif !important;
}

.Container {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.MainDivCustomer {
    padding-top: 100px;
    padding-bottom: 60px;
    width: 100%;
    position: relative;
}

.MainDivCustomer:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    background-image: linear-gradient(#fff 40%, rgb(0 0 0 / 0%));
    z-index: 1;
}

.BackGroundGra {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: .7;
    top: 0;
    z-index: -1;
    object-fit: cover;
}

.BookingCard {
    padding: 0px 0;
    width: 100%;
}

.BookingCardUnder {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    position: relative;
    width: 100%;
}

.BookingCardUnderTop {
    position: relative;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 4px 8px 0 rgba(51,51,51,.06);
    border-radius: 6px;
    padding: 15px 20px;
    margin: 8px 0;
    border: 1px solid #e5e8eb;
}

.BookingCardUnderTop img {
    height: auto;
    margin-right: 30px;
    width: 160px;
}

.BookingCarDeSec {
    position: relative;
    z-index: 1;
    width: 42%;
}

.BookingCarDeSec2 {
    position: relative;
    z-index: 1;
    width: 30%;
}

.ButtonArea {
    width: 20%;
}

.BookingCarDeTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    color: #000;
    margin: 0 0 0px 0;
    display: flex;
    align-items: center;
}

.BookingCarDeTitle span.Pending {
    font-size: 11px;
    font-weight: 500;
    background: #ffc107;
    color: #000;
    padding: 4px 8px;
    border-radius: 4px;
    height: auto;
    line-height: 14px;
    margin-left: 15px;
}

.BookingCarDeTitle span.Confirmed {
    font-size: 11px;
    font-weight: 500;
    background: #006;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    height: auto;
    line-height: 14px;
    margin-left: 15px;
}

.BookingCarDeTitle span.Completed {
    font-size: 11px;
    font-weight: 500;
    background: #008000;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    height: auto;
    line-height: 14px;
    margin-left: 15px;
}


.BookingCarDeTitle span.Cancelled {
    font-size: 11px;
    font-weight: 500;
    background: #dc3545;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    height: auto;
    line-height: 14px;
    margin-left: 15px;
}

.BookingCarDeSTitle {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #3F4254;
    margin: 0 0 0px 0;
    padding: 5px 0 5px 0;
    display: flex;
    align-items: center;
}

.BookingCarDeSTitle span {
    color: #7E8299;
    font-weight: 500;
    width: 150px;
    display: inline-block;
}

.BookingCarDeSTitle3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #3F4254;
    margin: 0 0 0px 0;
    padding: 7px 0 7px 0;
    display: flex;
    align-items: flex-start;
}

.BookingCarDeSTitle svg {
    min-width: 18px;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    margin-top: 2px;
}

.BookingCarDeSTitle3 svg {
    min-width: 18px;
    width: 18px;
    height: 18px;
    margin-right: 12px;
    color: #e64f22;
}

.BookingCarDeSTitle2{
    font-size: 18px;
    margin: 0 0 5px 0;
}

.BookingCarFeatured {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
}

.BookingCarFeatured span {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #5a5a5a;
    margin-right: 15px;
    display: flex;
    align-items: center;
    padding-bottom: 3px;
}

.BookingCarFeatured span:last-child {
    margin-right: 0;
}

.BookingCarFeatured span img {
    width: 16px;
    height: 16px;
    margin-right: 7px;
}

.BookCarInfoLink {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #006 !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.BookCarInfoLink::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 1px;
    bottom: -3px;
    left: 0px;
    background: #006;
    transition: all .3s ease-in-out;
}

.BookCarInfoLink:hover {
    color: #006 !important;
}

.BookCarInfoLink:hover::after {
    width: 92px;
}

.BookingCardUnderRow {
    width: Calc(100% - 220px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.BookCarViewBU {
    width: 163px;
    font-size: 13.5px;
    font-weight: 500;
    padding: 12px 14px;
    background-color: #7C4836;
    color: #fff;
    letter-spacing: .5px;
    border: none;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
}

.BookCarCancelBU {
    width: 163px;
    font-size: 13.5px;
    font-weight: 500;
    padding: 10px 14px;
    background-color: #ffffff;
    color: #d91515;
    letter-spacing: .5px;
    border: 1px solid #d91515;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
    margin-top: 15px;
}

.BookCarCancelBU:hover {
    background-color: #d91515;
    color: #fff;
}

.BookCarViewBU span {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
}

.BookCarViewBU span svg{
    width: 18px;
    height: 18px;
    margin-left: 11px;
    border: 1px solid #fff;
    border-radius: 50%;
}

.BookingCardUnder.Open .BookCarViewBU span svg {
    transform: rotate(180deg);
}

.BookCarViewBU:after{
    position: absolute;
    transition: 0.3s;
    content: "";
    width: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 0;
}

.BookCarViewBU:hover:after {
    width: 100%;
    left: 0;
}

.BookingCardUnderBottom {
    width: 100%;
    background: #f3f3f3;
    border-radius: 8px;
    padding: 20px;
    margin-top: 50px;
    display: none;
}

.BookingCardUnder.Open .BookingCardUnderBottom {
    display: block;
}

.BookingCardUnderBottomRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.BookingDeConSec {
    width: 50%;
}

.SmallSecSideTitle {
    margin: 0 0 15px 0;
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

.SmallCardDESec {
    width: 100%;
    margin-bottom: 25px;
}

.SmallCardDESec2 {
    width: 100%;
    margin-bottom: 6px;
}

.SmallCardDESec:last-child {
    margin-bottom: 0;
}

.SmallCardDESecHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.SmallCardDESecTitle {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.SmallCardDEList {
    margin: 0 0 5px 0;
    font-size: 13px;
    font-weight: 500;
    color: #606060;
}

.SmallCardDEList span {
    color: #333;
    margin-right: 8px;
    font-weight: 600;
    width: 125px;
    display: inline-block;
}

.CusDeArea {
    margin-bottom: 25px;
}

.SideCarDeBodyRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SmallCardTaxSec {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    padding: 12px 0;
}

.SmallCardTaxTitle {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.SmallCardTaxAmm {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    color: #333;
}

.SmallCardTotal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 15px;
}

.SmallCardTOtalAmm {
    margin: 0;
    font-size: 25px;
    font-weight: 700;
    color: #333;
    position: relative;
}

/* .SmallCardTOtalAmm::before {
    content: '$';
    position: absolute;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    left: -15px;
    top: 4px;
} */

.NofileSec {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgb(0 0 0 / 16%);
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 450px;
}

.NofileSecUnder {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    opacity: .4;
}

.NofileSecUnder img {
    width: 120px;
    margin-bottom: 40px;
}

.NofileSecUnder p {
    width: 100%;
    font-size: 28px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 0;
}

.CusRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.CustomerSideBar {
    width: 300px;
}

.CustomerDeSec {
    width: Calc(100% - 350px);
}

.MyBookConHead {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.MyBookConDeSec {
    width: 50%;
    padding: 15px 15px 0 0;
    border-top: 1px solid #c9c9c9;
}

.ActionBU {
    background-color: #e64f22 !important;
    color: #fff !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 90px !important;
    padding: 6px 4px !important;
    margin: 0 !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}

.ActionMenuSec ul {
    padding: 8px 0 !important;
}

.ActionMenuSec ul li {
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
}

.BookListSearch {
    width: 100%;
    display: flex;
    border: 1px solid #757575;
    border-radius: 6px;
    align-items: center;
    padding: 5px;
    margin-bottom: 15px;
}

.BookListSearch input {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #343434;
    padding: 5px 8px;
    border: none;
}

.BookListSearch input:focus {
    outline: none;
}

.BookListSearch button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #343434;
    border: none;
    background-color: transparent;
}

.BookListSearch button:hover,
.BookListSearch button:focus {
    outline: none;
}

@media (min-width: 360px) {
    .BookingCardUnderRow {
        width: 100%;
        margin-top: 30px;
    }
    .BookingCarDeSec {
        width: 100%;
        margin-bottom: 15px;
    }    
    .BookingCarDeSec2 {
        width: 100%;
        margin-bottom: 15px;
    }    
    .ButtonArea {
        width: 100%;
    }
    .BookCarViewBU {
        margin-right: 15px;
    }
}

@media (min-width: 992px) {
    .BookingCarDeSec {
        width: 42%;
        margin-bottom: 0;
    }    
    .BookingCarDeSec2 {
        width: 35%;
        margin-bottom: 0;
    }    
    .ButtonArea {
        width: 20%;
    }
    .BookCarViewBU {
        margin-right: 0;
    }
    .CustomerSideBar {
        width: 260px;
    }    
    .CustomerDeSec {
        width: Calc(100% - 280px);
    }
}

@media (min-width: 1200px) {
    .BookingCardUnderRow {
        width: Calc(100% - 220px);
        margin-top: 0;
    }
    .CustomerSideBar {
        width: 300px;
    }    
    .CustomerDeSec {
        width: Calc(100% - 350px);
    }
}

@media (min-width: 1400px) {   
    .BookingCarDeSec2 {
        width: 30%;
    }
}

.SmallCardTaxSecNew{
    border-top:none;
}

@media (max-width: 991px) {
    .CustomerSideBar {
        display: none;
    }
    .CustomerDeSec {
        width: 100%;
    }
    .MainDivCustomer {
        padding-top: 50px;
        padding-bottom: 40px;
    }
}

@media (max-width: 767px) {
    .MyBookConDeSec {
        width: 100%;
        padding: 10px 0 10px 0;
        border-top: 1px solid #c9c9c9;
    }
    .BookingCardUnder {
        padding: 15px;
    }
}