.MainDiv {
    width: 100%;
}

.PageTitleWrap {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ebebeb;
    box-shadow: 0 3px 10px rgb(62 85 120 / 7%);
    padding: 1rem 1.8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.PageTitle {
    margin: 0;
    color: #505050;
    font-size: 18px;
    font-weight: 500;
}

.MainCard {
    padding: 2rem 2.25rem;
    border-radius: 6px;
    position: relative;
    background: #fff;
    border: none;
    box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    margin: 40px auto 60px;
    width: 100%;
    max-width: 1250px;
}

.MainCard1{
    background: #F9DAD0;
}

.MainCard2{
    background: #D1E7FC;
}

.AddUserMain {
    width: 100%;
    padding: 15px;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: .875rem;
}

.FormGroup {
    width: 48%;
    padding-bottom: 20px;
    font-size: .875rem;
    position: relative;
}

.FormLabel {
    font-size: 13px;
    line-height: 30px;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.FormControl {
    display: block;
    width: 100%;
    min-width: 150px;
    height: 38px;
    padding: 0.375rem 0.4rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.FormControl:focus {
    outline: none;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 0;
}

.FormButtonArea {
    width: 100%;
}

.CancelBU {
    padding: 12px 30px;
    background-color: #fff;
    margin: 15px 15px 0 0;
    font-size: 14px;
    width: auto;
    font-weight: 600;
    line-height: 14px;
    border-radius: 0.5rem;
    color: #006;
    border: 1px solid #006;
    text-align: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
    outline: none;
    display: inline-block;
}

.CancelBU:hover {
    background-color: #006;
    color: #fff;
}

.SubmitBU {
    padding: 12px 30px;
    background-color: #006;
    margin: 15px 15px 0 0;
    font-size: 14px;
    width: auto;
    font-weight: 600;
    line-height: 14px;
    border-radius: 0.5rem;
    color: #fff;
    text-align: center;
    border: 1px solid #006;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor: pointer;
    outline: none;
}

.SubmitBU:hover {
    background-color: #010133;
    border: 1px solid #010133;
}

.ActiveBU {
    background-color: #40bf0c !important;
    border-color: #40bf0c !important;
    transition: all .3s ease-in-out;
}

.ActiveBU:hover {
    background-color: #329909 !important;
    border-color: #329909 !important;
}

.ArchiveBU {
    background-color: #7c4836 !important;
    border-color: #7c4836 !important;
    transition: all .3s ease-in-out;
}

.ArchiveBU:hover {
    background: #5c3324 !important;
    border-color: #5c3324 !important;
}

.ActionDiv {
    display: flex;
}

.ActionDiv a {
    color: #424242;
    margin-right: 10px;
}

.ActionDiv a svg,
.ActionDiv button {
    width: 20px;
    height: 20px;
}

.ActionDiv button {
    width: 20px;
    min-width: 20px;
    height: 20px;
    padding: 0;
    color: #424242;
}

.StepOneForm {
    width: 100%;
    margin: 40px 0 10px 0;
}

.StepOneFormRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.StepOneFormFull {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    width: 100%;
}

.StepOneFormBig {
    width: 55%;
}

.StepOneFormSmall{
    width: 40%;
    padding: 70px;
    position: relative;
}

.StepOneFormSmall:before {
    content: '';
    position: absolute;
    width: 1px;
    height: Calc(100% - 160px);
    background: #ccc;
    bottom: 80px;
    left: 20px;
}

.StepOneFormSmall2 {
    width: 40%;
    padding: 0px 0 70px 70px;
    position: relative;
}

.StepOneFormSmall2:before {
    content: '';
    position: absolute;
    width: 1px;
    height: Calc(100% - 50px);
    background: #ccc;
    bottom: 10px;
    left: 20px;
}

.FormFieldFull {
    width: 100%;
    padding: 8px 0;
}

.StepOneFormFull .FormFieldFull {
    width: 100%;
    padding: 8px;
}

.FormFieldHalf {
    width: 50%;
    padding: 8px;
}

.StepOneFormFull .FormFieldHalf {
    width: 50%;
    padding: 8px;
}

.FormFieldOneThird {
    width: 33.33%;
    padding: 8px;
}

.CustomSelect>div {
    /* border-color: #F9F9F9 !important; */
    border-radius: 8px !important;
    min-height: 44px !important;
    font-weight: 400;
    font-size: 14px;
    background: #F9F9F9;
    color: #5E6278;
}

.FormFieldHalf label,
.FormFieldFull label,
.FormFieldOneThird label {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 7px;
    color: #2a2a2a;
    width: 100%;
}

.FormFieldHalf label span,
.FormFieldFull label span,
.FormFieldOneThird span {
    color: #f40;
}

.TextField {
    width: 100%;
    background: #F9F9F9;
    border: 1px solid #cccccc !important;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 11px 16px;
}

.ItemsCard {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -8px 0px -8px;
    position: relative;
    padding-bottom: 10px;
}

.CardWiseSec .ItemsCard {
    margin: 20px -8px 0px -8px;
}

.TextFieldAutoComplete{
    padding: 0;
    border:none;
}

.TextFieldAutoComplete input{
    padding: 2px 7px !important;
}

textarea.TextField {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #cccccc;
    border-radius: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 11px 16px;
    height: 160px;
    resize: none;
}

.TextField:focus,
textarea.TextField:focus {
    outline: none;
}

.CheckBox span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #000000;
}

.CostUL {
    margin: 0;
    padding: 0;
    width: 100%;
}

.CostUL li {
    list-style: none;
    display: flex;
    padding: 6px 0;
}

.CostTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #000000;
    width: 180px;
}

.CostNo {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #000000;
}

.CostTitle.Big,
.CostNo.Big {
    font-size: 22px;
    line-height: 30px;
}

.StepBUSec {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 50px;
}

.FormInsideRow {
    display: flex;
}

.FieldWrap {
    display: flex;
}

.FieldWrap>div {
    width: 100%;
}

.AddBU {
    color: #e64f22 !important;
    padding: 9px 15px !important;
    text-decoration: none !important;
    background: #fadfd7 !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0 0 0 18px !important;
    width: 110px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border: 1px solid #fadfd7;
}

.AddBU:hover {
    color: #fff !important;
    background: #e64f22 !important;
    border: 1px solid #e64f22;
}

.ItemDeleteBU {
    color: #e64f22 !important;
    padding: 9px 15px !important;
    text-decoration: none !important;
    background: #fff !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 10px 0 0 0px !important;
    width: 90px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border: none;
    /* position: absolute;
    left: 0px;
    bottom: -40px; */
}

.ItemDeleteBU svg {
    min-width: 18px;
    min-height: 18px;
    margin-right: 8px;
}

.ItemAddBU {
    color: #e64f22 !important;
    padding: 9px 15px !important;
    text-decoration: none !important;
    background: #e64f222e !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0 0 0 100px !important;
    width: 160px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border: none;
    position: relative;
    bottom: 50px;
}

.ItemAddBU:hover {
    color: #fff !important;
    background: #e64f22 !important;
}

.CardWiseSec {
    width: 100%;
    margin-bottom: 40px;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 0px;
}

.AddPadd {
    padding-top: 30px;
}

.StepBUSec .Next {
    color: #fff !important;
    padding: 15px 15px !important;
    text-decoration: none !important;
    background: #e64f22 !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0 0 0 auto !important;
    width: 160px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border: none;
}

.StepBUSec .Previous {
    color: #7E8299 !important;
    padding: 15px 15px !important;
    text-decoration: none !important;
    background: #F9F9F9 !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0 0 0 0 !important;
    width: 160px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border: none;
}

.AltConSec {
    width: 100%;
    display: flex;
}

.ConNoBU {
    color: #e64f22 !important;
    padding: 9px 15px !important;
    text-decoration: none !important;
    background: #e64f222e !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0 0 0 10px !important;
    width: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border: none;
}

.AddAltConNO {
    color: #e64f22 !important;
    padding: 9px 15px !important;
    text-decoration: none !important;
    background: #e64f222e !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 10px 0 0 0px !important;
    width: 160px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border: none;
}

.AddAltConNO svg {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}

.AddAltConNO:hover {
    color: #fff !important;
    background: #e64f22 !important;
}

.AddAnoPadd {
    padding-top: 40px;
}

.FindLocationBU {
    color: #e64f22 !important;
    padding: 9px 15px !important;
    text-decoration: none !important;
    background: #e64f222e !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0px 0 0 12px !important;
    min-width: 130px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border: none;
}

.FindLocationBU:hover {
    color: #fff !important;
    background: #e64f22 !important;
}

.CustomWidth {
    width: 90%;
    margin: 0 auto;
}

.SummerySec {
    width: 100%;
    margin: 50px 0;
}

.SummerySecRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.SummerySecFull {
    width: 100%;
    display: flex;
}

.SummerySecFull p {
    font-size: 18px;
    font-weight: 600;
    color: #3F4254;
    margin: 0 8px 40px 0;
}

.SummerySecHalf {
    width: 48%;
}

.SummerySecHalf1{
    background: #F9DAD0;
}

.SummerySecHalf2{
    background: #D1E7FC;
}

.StepBoxTitle2 {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin: 0 0 10px 0;
    background: #E64F22;
    padding: 8px 12px 8px 12px;
    border-radius: 2px;
}

.SummeryListSec {
    width: 100%;
    margin: 0;
    padding: 0;
}

.SummeryListSec li {
    list-style: none;
    display: flex;
    padding: 10px 0;
}

.SummeryHeadTitle {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #7E8299;
    width: 120px;
}

.SummeryHeadText {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3F4254;
    width: 200px;
}

.StepBoxTitle {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin: 0 0 10px 0;
    background: #E64F22;
    padding: 8px 12px 8px 12px;
    border-radius: 2px;
}

.TableSec {
    width: 100%;
    margin: 10px 0 60px 0;
}

.TableSec table {
    width: 100%;
}

.TableHead {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    padding: 0.75rem 0.75rem;
    text-align: right;
    border-bottom: 1px solid #e3e3e3;
}

.TableHead:first-child,
.TableCon:first-child {
    padding-left: 0;
    text-align: left;
}

.TableHead:last-child {
    padding-right: 0;
}

.TableCon {
    font-size: 15px;
    font-weight: 600;
    color: #5E6278;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    text-align: right;
    border-bottom: 1px solid #e3e3e3;
}

.SummeryPPTI {
    text-align: right;
}

.TableCon:last-child {
    padding-right: 0;
    color: #181C32;
}

.TableConSec {
    width: 100%;
    display: flex;
    padding: 8px 0;
}

.TableConSecTitle {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #868686;
    min-width: 130px;
}

.TableConSecText {
    margin: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #4D4D4D;
}

.BookADelivery {
    width: 100%;
    padding: 10px 0 0px 0;
}
.BookADeliveryCardArea {
    width: 100%;
    border-bottom: 1px solid #DEDFE0;
    margin-bottom: 30px;
}
.HomeBU {
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 28px;
    text-transform: uppercase;
    height: 48px;
    border-radius: 8px;
    border: none;
    background: #e64f22;
    margin: 28px auto 40px auto;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.HomeBU:before,
.NextBU:before {
    content: "";
    position: absolute;
    top: -180%;
    left: -100%;
    width: 150%;
    height: 300%;
    opacity: 0;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    background: hsla(0,0%,100%,0);
    background: -webkit-gradient(linear,left top,right top,from(hsla(0,0%,100%,0)),color-stop(70%,hsla(0,0%,100%,0)),color-stop(74%,hsla(0,0%,100%,.4)),color-stop(76%,hsla(0,0%,100%,.2)),color-stop(77%,hsla(0,0%,100%,0)),color-stop(79%,hsla(0,0%,100%,.1)),color-stop(92%,hsla(0,0%,100%,.5)),to(hsla(0,0%,100%,0)));
    background: -webkit-linear-gradient(left,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
    background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
}
.HomeBU:hover:before,
.NextBU:hover:before{
    opacity: 1;
    top: -160%;
    left: 15%;
    -webkit-transition-property: left,top,opacity;
    transition-property: left,top,opacity;
    -webkit-transition-duration: .7s,.7s,.15s;
    transition-duration: .7s,.7s,.15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}
.BookDeliveryCard {
    width: 100%;
    position: relative;
    background: #f5f5f5;
    border-radius: 8px;
    padding-right: 52px;
    /* overflow: hidden; */
    margin-bottom: 15px;
}
.CardTrashBU {
    position: absolute;
    top: 0;
    right: 0;
    width: 53px;
    height: 100%;
    background: #e64f22;
    border: none;
    color: #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.BookDeliveryCardUnder {
    width: 100%;
    padding: 15px 20px;
}

.BookDeliveryCardUnderRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.BookDeliveryFormCard30 {
    width: 30%;
    padding: 0 15px;
}
.FormField {
    width: 100%;
    padding: 8px 0;
}

.FormField2 {
    width: 100%;
    padding: 8px 0;
    max-width: 500px;
}

.FormField label,
.FormField2 label {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 7px;
    color: #4D4D4D;
    width: 100%;
}

.FormField label span {
    color: #f40;
}

.FormField.CusWidth {
    width: 180px;
    margin-right: 15px;
}
.BookDeliveryFormCard10 {
    width: 10%;
    padding: 0 15px;
}
.BookDeliveryFormCard20 {
    width: 20%;
    padding: 0 15px;
}
.BookDeliveryFormCard25 {
    width: 25%;
    padding: 0 15px;
}