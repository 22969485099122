.FooterMain {
    width: 140px!important;
    overflow: unset;
    z-index: 0;
    left: 0;
    right: auto;
    top: 0;
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    position: fixed;
    flex-direction: column;
    background-color: #1e1e2d;
    transition: all .3s ease-in-out;
}

.SideAvatarMain {
    padding: 2rem 25px !important;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1B1B28;
}

.SideAvatarMain img {
    height: 60px;
}

.SideAvatarMainUnder {
    padding: 10px 21px 10px 22px!important;
    transition: all .4s ease-in-out;
    box-sizing: border-box!important;
    width: 100%!important;
    display: flex;
}

.SideAvatarSec {
    padding: 4px;
}

.SideAvatarSecUnder {
    margin-right: 4px;
    display: inline-flex;
    position: relative;
    flex-shrink: 0;
    vertical-align: middle;
}

.SideAvatar {
    background-color: #662b19;
    color: #F7F9FC;
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.1607142857142858rem;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
}

.UserActiveState {
    border: 2px solid rgb(255, 255, 255);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #44ac09;
    color: #fff;
    right: 14%;
    bottom: 14%;
    transform: scale(1) translate(50%, 50%);
    transform-origin: 100% 100%;
    display: flex;
    z-index: 1;
    position: absolute;
    flex-wrap: wrap;
}

.SideUserName {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 4px 4px 4px 10px;
    transition: all .3s ease-in-out;
}

.SideUserName span {
    font-size: 14px;
    font-weight: 500;
    color: #eee;
}

.SideMenuLinkSec {
    position: relative;
    height: 100%;
    overflow: unset;
    touch-action: auto;
    width: 100%;
}

.SideMenuLinkSecUnder {
    padding: 10px 0;
}

.SideMenuLinkSecUnder ul {
    padding: 0;
    margin: 0;
}

.SideMenuLinkSecUnder ul li {
    list-style: none;
    padding: 0.5rem 0;
    position: relative;
}

.SideMenuLinkSecUnder ul li a {
    padding: 0.65rem 0rem;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 75px;
    margin: 0 auto;
    border-radius: 0.475rem;
    cursor: pointer;
    transition: all .3s ease-in-out;
    text-decoration: none;
}

.SideMenuLinkSecUnder ul li a:hover {
    background-color: #1b1b28;
}

.SideMenuLinkSecUnder ul li a svg {
    max-width: 26px;
    min-height: 22px;
    color: #3A3A5D;
    font-size: 20px;
    width: auto;
    height: auto;
    opacity: 1;
    stroke-width: 1.5;
}

.SideMenuLinkSecUnder ul li a svg.DownIndi {
    min-width: 20px;
    min-height: 20px;
    color: rgb(247 165 141);
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.6;
    stroke-width: 2;
    margin-left: auto;
    transform: rotate(0deg);
}

.SideMenuLinkSecUnder ul li.DropDownSec.Open a svg.DownIndi {
    transform: rotate(-180deg);
}

.SideLinkText {
    color: #9899ac;
    width: 100%;
    font-size: 13px;
    padding: 8px 0px 0 0;
    text-align: center;
    display: block;
    font-weight: 500;
    margin: 0;
    transition: all .3s ease-in-out;
}

.SideMenuLinkSecUnder ul li.Active a svg,
.SideMenuLinkSecUnder ul li.Active .SideLinkText {
    color: #fff;
}

.SideMenuLinkSecUnder ul li.Active a svg {
    color: #e64f22;
}

.DropDownList {
    min-height: 39px;
    max-height: 500px;
    position: absolute;
    left: 140px;
    background: #fff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    border-radius: 5px;
    top: 0;
    transform: scale(0);
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.DropDownSec.Open .DropDownList,
.DropDownSec:hover .DropDownList,
.DropDownList:hover {
    transform: scale(1);
    opacity: 1;
}

.SideMenuLinkSecUnder ul li .DropDownList li {
    list-style: none;
    padding: 0;
}

.SideMenuLinkSecUnder ul li .DropDownList li a {
    font-size: 13px;
    font-weight: 500 !important;
    color: #5E6278;
    padding: .75rem 1.5rem !important;
    transition: all .3s ease-in-out;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    width: 220px;
    height: auto;
    margin: 0 auto;
    border-radius: 0;
    cursor: pointer;
    transition: all .3s ease-in-out;
    text-decoration: none;
}

.SideMenuLinkSecUnder ul li .DropDownList li a:hover {
    background-color: #fff;
    color: #cb4a24;
}