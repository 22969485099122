@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

*, body {
    font-family: 'Noto Sans', sans-serif !important;
    margin: 0;
    padding: 0;
}

.Heading {
    font-family: 'Roboto Slab', serif !important;
}

.Container {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.Row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0px;
}

.BannerSec {
    width: 100%;
}

.Banner {
    width: 100%;
    height: 900px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding-top: 0px;
}

.BannerBG {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    object-fit: cover;
    object-position: center;
}

.Banner:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
}

.BannerContent {
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
}

.BannerText {
    width: 100%;
}

.BannerTitle {
    font-size: 50px;
    line-height: 70px;
    letter-spacing: 1px;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase;
    width: 700px;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.BannerTitle:after {
    content: '';
    position: absolute;
    width: 100px;
    height: 3px;
    background: #e64f22;
    bottom: 0;
    left: 0;
}

.BannerText {
    font-size: 21px;
    padding: 3px 0;
    line-height: 35px;
    color: #fff;
    font-weight: 500;
    width: 700px;
}

.HomeBU {
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 28px;
    text-transform: uppercase;
    height: 48px;
    border-radius: 8px;
    border: none;
    background: #e64f22;
    margin-top: 20px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.FeatresSec {
    width: 100%;
    background: #e64f22;
    height: 400px;
}

.OneThird {
    width: 33.33%;
    padding: 15px;
}

.OneThirdUnder {
    width: 100%;
    background: #fff;
}

.freturesImg {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
}

.FeatresSec .simpleParallax {
    position: absolute;
    height: 400px;
    width: 100%;
}

.FeatresSec .simpleParallax img {
    opacity: .15;
}

.FeatresSec img.thumbnail {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: top;
    opacity: .15;
}

.FeatresPosition {
    position: relative;
    z-index: 1;
    top: -265px;
}

.freturesCon {
    padding: 25px 30px;
}

.freturesConTitle {
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 1px;
    color: #262626;
    margin-bottom: 12px;
    position: relative;
    padding-top: 12px;
}

.freturesConTitle:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 3px;
    background: #e64f22;
    top: 0;
    left: 0;
}

.freturesConText {
    font-size: 14px;
    font-weight: 400;
    color: #525252;
    margin-bottom: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.Offers {
    width: 100%;
    padding: 70px 0;
    position: relative;
}

.OffersTitle {
    font-size: 28px;
    text-align: center;
    font-weight: 900;
    color: #262626;
    margin-bottom: 5px;
}

.OffersTitleSM {
    font-size: 14px;
    text-align: center;
    color: #858585;
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 20px;
}

.OffersTitleSM:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background: #e64f22;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.OffersHalf {
    width: 50%;
    padding: 15px;
}

.OffersHalfUnder {
    width: 100%;
    display: flex;
    align-items: center;
    background: #f7f7f7;
    padding: 25px 30px;
}

.OffersHalf img {
    width: 80px;
    height: auto;
    margin-right: 30px;
}

.OffersBG {
    position: absolute;
    bottom: 0;
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.OfferCardTitle {
    font-size: 18px;
    font-weight: 700;
    color: #383838;
    margin-bottom: 12px;
}

.OfferCardText {
    font-size: 14px;
    font-weight: 400;
    color: #5c5c5c;
    margin-bottom: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.WhyChooseSec {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #e64f22;
}

.WhyChooseHalf {
    width: 50%;
}

.WhyContainer {
    width: 570px;
    margin: 0 0 0 auto;
    padding: 60px 60px 60px 0;
}

.WhyChooseHalf .SideIMG {
    width: 100%;
    height: 100%;
}

.WhyChooseTitle {
    font-size: 22px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 30px;
}

.WhyChooseSec ul {
    padding: 0;
}

.WhyChooseSec ul li {
    list-style: none;
    position: relative;
    padding-left: 30px;
    padding-bottom: 15px;
}

.WhyChooseSec ul li::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #002e5b;
    left: 0;
    top: 9px;
    border-radius: 50%;
}

.WhyChooseListTitle {
    font-size: 18px;
    font-weight: 600;
    color: #002e5b;
    margin-bottom: 12px;
}

.WhyChooseListText {
    font-size: 14px;
    font-weight: 400;
    color: #dfdfdf;
    margin-bottom: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.Testimonial .item {
    background-color: #fff;
    box-shadow: 0px 0px 4px #ccc;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 10px;
}

.TestiHead {
    display: flex;
    align-items: center;
}

.TestiHead img {
    width: 60px !important;
    height: 60px;
    margin-right: 25px;
}

.TestiName {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 12px;
}

.TestiDes {
    font-size: 14px;
    color: #9EA1AB;
    font-weight: 400;
}

.TestiCon {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.HomeBU {
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 28px;
    text-transform: uppercase;
    height: 48px;
    border-radius: 8px;
    border: none;
    background: #e64f22;
    margin-top: 20px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.WhyChooseBU {
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 28px;
    text-transform: uppercase;
    height: 48px;
    border-radius: 8px;
    border: none;
    background: #002e5b;
    margin-top: 30px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: 30px;
}

.FeatresBU {
    font-size: 14px;
    line-height: normal;
    display: block;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 10px 28px;
    text-transform: uppercase;
    height: 48px;
    border-radius: 8px;
    border: none;
    background: #002e5b;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin: 80px auto 0 auto;
}

.HomeBU:before,
.FeatresBU::before,
.WhyChooseBU::before {
    content: "";
    position: absolute;
    top: -180%;
    left: -100%;
    width: 150%;
    height: 300%;
    opacity: 0;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    background: hsla(0,0%,100%,0);
    background: -webkit-gradient(linear,left top,right top,from(hsla(0,0%,100%,0)),color-stop(70%,hsla(0,0%,100%,0)),color-stop(74%,hsla(0,0%,100%,.4)),color-stop(76%,hsla(0,0%,100%,.2)),color-stop(77%,hsla(0,0%,100%,0)),color-stop(79%,hsla(0,0%,100%,.1)),color-stop(92%,hsla(0,0%,100%,.5)),to(hsla(0,0%,100%,0)));
    background: -webkit-linear-gradient(left,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
    background: linear-gradient(90deg,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0) 70%,hsla(0,0%,100%,.4) 74%,hsla(0,0%,100%,.2) 76%,hsla(0,0%,100%,0) 77%,hsla(0,0%,100%,.1) 79%,hsla(0,0%,100%,.5) 92%,hsla(0,0%,100%,0));
}

.HomeBU:hover:before,
.FeatresBU:hover:before,
.WhyChooseBU:hover:before {
    opacity: 1;
    top: -160%;
    left: 15%;
    -webkit-transition-property: left,top,opacity;
    transition-property: left,top,opacity;
    -webkit-transition-duration: .7s,.7s,.15s;
    transition-duration: .7s,.7s,.15s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

@media (min-width: 360px) {
    .Banner {
        height: 500px;
    } 
    .BannerContent {
        height: 500px;
    }
    .FeatresPosition {
        top: 0px;
    }
    .FeatresSec {
        height: auto;
        padding: 25px 0;
    }
    .OneThird {
        width: 100%;
    }
    .FeatresBU {
        margin: 50px auto 20px auto;
    }
    .OffersHalfUnder {
        padding: 0;
        flex-wrap: wrap;
    }
    .OffersHalf img {
        margin-right: 0px;
        margin-bottom: 30px;
    }
    .WhyContainer {
        width: 100%;
        padding: 20px 20px 20px 20px;
    }
    .BannerText {
        width: 100%;
        font-size: 18px;
        line-height: 30px;
    }
    .BannerTitle {
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 3px;
        font-weight: 900;
        width: 100%;
    }
    .OffersHalf {
        width: 100%;
    }
    .WhyChooseHalf {
        width: 100%;
    }
}

@media (min-width: 576px) {
    .Banner {
        height: 500px;
    } 
    .BannerContent {
        height: 500px;
    }
    .OffersHalfUnder {
        padding: 10px 10px;
        flex-wrap: nowrap;
    }
    .OffersHalf img {
        margin-right: 30px;
        margin-bottom: 0px;
    }
    .WhyContainer {
        width: 100%;
        padding: 60px 60px 60px 20px;
    }
    .BannerText {
        width: 100%;
        font-size: 18px;
        line-height: 30px;
    }
    .BannerTitle {
        font-size: 36px;
        line-height: 46px;
        letter-spacing: 3px;
        font-weight: 900;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .OneThird {
        width: 50%;
    }
    .FeatresBU {
        margin: 50px auto 20px auto;
    }
    .OffersHalfUnder {
        padding: 10px 10px;
    }
    .WhyContainer {
        width: 100%;
        padding: 60px 60px 60px 20px;
    }
    .BannerText {
        width: 700px;
        font-size: 21px;
        line-height: 35px;
    }
    .BannerTitle {
        font-size: 50px;
        line-height: 70px;
        letter-spacing: 1px;
        font-weight: 900;
        width: 700px;
    }
    .OffersHalf {
        width: 50%;
    }
    .WhyChooseHalf {
        width: 50%;
    }
}

@media (min-width: 992px) {
    .WhyContainer {
        width: 500px;
        padding: 60px 60px 60px 20px;
    }
    .Banner {
        height: 900px;
    } 
    .BannerContent {
        height: 700px;
    }
    .FeatresPosition {
        top: -265px;
    }
    .FeatresSec {
        height: 400px;
        padding: 0;
    }
    .OneThird {
        width: 33.33%;
    }
    .FeatresBU {
        margin: 80px auto 0px auto;
    }
    .OffersHalfUnder {
        padding: 25px 30px;
    }
    .WhyContainer {
        width: 570px;
        padding: 60px 60px 60px 0;
    }
}

@media (min-width: 1200px) {
    .WhyContainer {
        width: 570px;
        padding: 60px 60px 60px 20px;
    }
}

@media (max-width: 799px) {}