.MainDiv {
    width: 100%;
}

.PageTitleWrap {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ebebeb;
    box-shadow: 0 3px 10px rgb(62 85 120 / 7%);
    padding: 1rem 1.8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.PageTitle {
    margin: 0;
    color: #505050;
    font-size: 18px;
    font-weight: 500;
}

.MainCard {
    padding: 20px;
    border-radius: 0.5rem;
    position: relative;
    background: #fff;
    border: none;
    box-shadow: 0 0 7px 2px rgb(62 85 120 / 10%);
    margin: 0 1.8rem 80px;
}

.AddUserMain {
    width: 100%;
    padding: 15px;
}

.FormRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: .875rem;
}

.FormGroup {
    width: 48%;
    padding-bottom: 20px;
    font-size: .875rem;
    position: relative;
}

.FormGroupFull {
  width: 100%;
  padding-bottom: 20px;
  font-size: .875rem;
  position: relative;
}

.FormLabel {
    font-size: 13px;
    line-height: 30px;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.FormControl {
    display: block;
    width: 100%;
    min-width: 150px;
    height: 38px;
    padding: 0.375rem 0.4rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.FormControl:focus {
    outline: none;
}

.ErrorM {
    color: #f44336;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 0;
}

.FormButtonArea {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.CancelBU {
  color: #7E8299 !important;
  padding: calc(0.65rem + 1px) calc(1.5rem + 1px) !important;
  text-decoration: none !important;
  background: #F9F9F9 !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-right: 18px !important;
}

.CancelBU:hover {
  background: #F4F4F4 !important;
}

.SubmitBU {
  color: #fff !important;
  padding: calc(0.65rem + 1px) calc(1.5rem + 1px) !important;
  text-decoration: none !important;
  background: #e64f22 !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: none;
}

.SubmitBU:hover {
    background-color: #c9451e !important;
}
.FileUpDuBUDiv {
    width: 200px;
    height: 140px;
    margin: 0 15px;
  }
  
  .FileUpDuBUDiv input {
    display: none;
  }
  
  .UploadLabel {
    padding: 5px 15px 5px 20px;
    background-color: transparent;
    font-size: 14px;
    width: 100%;
    display: -webkit-inline-flex;
    display: inline-flex;
    font-weight: 500;
    -webkit-align-items: center;
    align-items: center;
    line-height: 14px;
    border-radius: .5rem;
    color: #1972b4;
    text-align: center;
    vertical-align: middle;
    border: 1px dashed #1972b47a;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    height: 100%;
    text-transform: capitalize;
    cursor: pointer;
    position: relative;
  }
  
  .UploadLabel svg {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
  }
  
  .UploadLabel div {
    display: none;
  }
  
  .UploadLabel span {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
  
  .UploadLabel div.UpIcon {
    display: block;
    width: 100%;
  }

  .UploadLabel span p {
    width: 100%;
    text-align: center;
  }
  
  .UploadText {
    margin: 0 0 5px 0;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    font-size: 10px !important;
  }

  .UploadText2 {
    margin: 0 0 5px 0;
    position: absolute;
    bottom: 12px;
    width: 100%;
    left: 0;
    font-size: 10px !important;
  }
  
  .FileIMGDiv {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  
  .ImgFile {
    width: 200px;
    height: 140px;
    position: relative;
    margin: 0 15px;
    border: 1px solid #c1c1c1;
    border-radius: 0.5rem;
  }
  
  .ImgFile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
  }
  
  .ImgFile a {
    position: absolute;
    background: #f40;
    color: #fff;
    border-radius: 50%;
    top: -5px;
    right: -5px;
    width: 23px;
    height: 23px;
    text-align: center;
  }
  
  .ImgFile a svg {
    width: 15px;
    height: 15px;
    margin-top: -5px;
  }
  
  .ImgFile button {
    position: absolute;
    background: #f40;
    color: #fff;
    border-radius: 50%;
    top: -5px;
    right: -5px;
    min-width: 23px;
    min-height: 23px;
    text-align: center;
    padding: 0;
    width: 23px;
    height: 23px;
  }
  
  .ImgFile button svg {
    width: 15px;
    height: 15px;
    margin-top: 0px;
  }