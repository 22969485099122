.CheckBox .Mui-checked {
  color: #27C27B !important;
}

.StepperSec .MuiStepLabel-root {
  flex-wrap: wrap;
  justify-content: center;
}

.StepperSec .MuiStepLabel-iconContainer {
  padding: 0 0 20px 0;
}

.StepperSec .MuiStepLabel-iconContainer .MuiSvgIcon-root {
  color: #E5E5E5;
}

.StepperSec .MuiStepLabel-iconContainer .Mui-active {
  color: #E64F22;
}

.StepperSec .MuiStepLabel-iconContainer .Mui-completed {
  color: #27C27B;
}

.StepperSec .MuiStepLabel-root .MuiStepLabel-labelContainer {
  text-align: center;
}

.StepperSec .MuiStepLabel-root .MuiStepLabel-labelContainer span {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #343434;
}

.StepperSec .MuiStepConnector-root {
  top: 12px;
  position: relative;
}

.StepperSec .MuiStepConnector-root span {
  border-color: #E5E5E5;
}

svg.MuiSvgIcon-root text {
  display: none;
}

.FormSec .PhoneInput {
  width: 100%;
}

.PhoneInputCountry {
  margin-right: 12px;
}

.DashboardMainBody {
  padding-left: 140px;
}

body {
  background-color: #f9f9f9 !important;
}

.AddBookingTab .MuiTabs-scroller .MuiButtonBase-root {
  color: #181C32;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  width: 25%;
  min-width: 220px;
}

.AddBookingTab .MuiTabs-scroller .MuiButtonBase-root.Mui-selected {
  color: #E64F22;
}

.AddBookingTab .MuiTabs-scroller .MuiTabs-indicator {
  background-color: #E64F22;
}

.AddBookingTab .MuiBox-root {
  width: 90%;
  margin: auto;
  border: none;
  padding: 0;
}

.CusModal .modal-title  {
  font-size: 19px;
}

.CusModal .btn-close {
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") !important;
  background-size: cover !important;
  padding: 0 !important;
  position: relative;
  margin-right: 0px !important;
}

.CusModal .modal-header {
  padding: 20px 20px;
  border-color: #f4f4f4;
}

.CusModal .modal-body {
  padding: 20px 20px;
}

.TableFilterHead {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.DataTableSearch {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
}

.DataTableSearch>div {
  width: 100%;
}

.DataTableSearch>div input {
  padding: 0;
  width: 100%;
  font-size: .875rem!important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border: none;
}

.DataTableSearch>div .MuiOutlinedInput-notchedOutline {
  display: none;
}

.DataTableSearch button {
  padding: 0;
  min-width: 30px;
}

.DataTableSearch button svg {
  width: 18px;
  height: 18px;
  color: #212529;
  opacity: .8;
}

.TableFilterBUSec {
  max-width: 410px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TableFilterBUSec a {
  color: #e64f22 !important;
  padding: 9px 15px !important;
  text-decoration: none !important;
  background: #e64f222e !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0 0 0 18px !important;
  width: 110px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.TableAddBU {
  color: #fff !important;
  padding: 9px 15px !important;
  text-decoration: none !important;
  background: #e64f22 !important;
  border: none !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0 0 0 18px !important;
  width:110px;
  height: 39px;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  transition: all .3s ease-in-out !important;
  text-transform: capitalize !important;
}

.TableAddBU span {
  display: none;
}

.TableFilterBUSec a svg,
.TableAddBU svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  margin-right: 6px;
}

.TableFilterBUSec a:hover {
  color: #fff !important;
  background: #e64f22 !important;
}

.DataTable .rdt_TableHeadRow,
.DataTable2 .rdt_TableHeadRow {
  border-bottom: 1px solid #e0e0e0;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
}

.DataTable .rdt_TableHeadRow div:hover,
.DataTable2 .rdt_TableHeadRow div:hover {
  color: #FFF !important;
  opacity: 1 !important;
}

.DataTable .rdt_TableHeadRow span,
.DataTable2 .rdt_TableHeadRow span {
  display: none;
}

.DataTable .rdt_TableBody,
.DataTable2 .rdt_TableBody {
  border-bottom: 1px solid #7E8299;
}

.DataTable .rdt_TableBody>div,
.DataTable2 .rdt_TableBody>div {
  min-height: 58px;
}

.DataTable .rdt_TableBody>div:nth-child(odd) {
  background: #f8f7f8;
}

.DataTable .rdt_TableBody div div div,
.DataTable2 .rdt_TableBody div div div {
  font-size: 14px;
  font-weight: 400;
}

.DataTable .rdt_TableHeadRow>div,
.DataTable .rdt_TableBody>div>div,
.DataTable2 .rdt_TableHeadRow>div,
.DataTable2 .rdt_TableBody>div>div {
  padding: 0 8px !important;
}

.DataTable .rdt_TableHeadRow>div div,
.DataTable .rdt_TableBody>div>div div,
.DataTable2 .rdt_TableHeadRow>div div,
.DataTable2 .rdt_TableBody>div>div div {
  white-space: normal !important;
}

.TableActionBU {
  font-size: 12.5px !important;
  color: #7E8299 !important;
  background: #F9F9F9 !important;
  text-transform: capitalize !important;
  padding: 6px 15px !important;
  transition: all .3s ease-in-out !important;
}

.TableActionBU svg {
  width: 18px;
  stroke-width: 1px;
}

.TableActionBU:hover {
  background: #e64f222e !important;
  color: #e64f22 !important;
}

.TableActionDP ul li {
  font-size: 14px;
  font-weight: 500;
  color: #464646;
  padding: 10px 20px;
  min-width: 140px;
}

fieldset {
  display: none;
}

.StatusDropDown button:after {
  display: none;
}

.StatusDropDown button {
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.StatusDropDown button svg {
  width: 18px;
  height: 18px;
  stroke-width: 1.5px;
  margin-left: 5px;
}

.StatusDropDown .dropdown-menu {
  min-width: 8rem!important;
  padding: 5px!important;
  border-radius: 5px!important;
  border: none!important;
  box-shadow: 1px 3px 20px 0 rgba(0,0,0,.1);
  font-size: 13px !important;
  font-weight: 500 !important;
 }

 .MuiList-root {
  min-width: 8rem!important;
  padding: 5px!important;
  border-radius: 5px!important;
  border: none!important;
  box-shadow: 1px 3px 20px 0 rgba(0,0,0,.1);
  font-size: 13px !important;
  font-weight: 500 !important;
 }

 @media (max-width: 991px) {
  .AddBookingTab .MuiBox-root {
    width: 100%;
  }
  .CusModal.OrderInfo {
    padding: 0 !important;
  }
  .CusModal.OrderInfo .modal-dialog {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  .CusModal.OrderInfo .modal-dialog .modal-content {
    border-radius: 0;
    border: 0;
  }
 }